import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, Spinner, Modal, ProgressBar, Alert, Badge } from 'react-bootstrap';

import { callServer, hashPassword } from '../utils/APIUtils';

import {
	setEditId,
	setEditMode,
	selectEditId,
	selectEditMode,
	EDITMODES,
} from '../store/editIdSlice';

import {
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup,
	selectSelectedSignup,
} from '../store/selectedSignupSlice';

import {
	setField,
	setBillerInfo,
	reset,
	addSignup,
	removeSignup,
	selectField,
	selectBillerInfo,
	selectSignupCount,
	selectAllSignupData,
	resetSignups,
	selectSignupsRevision,
	SIGNUPFIELDS,
	BILLERINFOFIELDS,
	setAllSignupData
} from '../store/signupsSlice';

import {
	selectOperator,
	selectUserCredentials,
	signOut
} from '../store/userSlice';

const AUTO_SAVE_TIME = 30000;
const AUTO_GET_TIME = 30000;

function SignupSaver(props)
{
	const MODAL_INIT = {showing: false, title:'', body:'', showClose:false};
	const MODAL_PROGRESS = {showing: true, title:'Sending Signups', body:<span><span>Running&#8230;</span><ProgressBar animated now={ 100 } /></span>, showClose:false};
	const MODAL_DONE = {showing: true, title:'Signups Sent', body:<span>Signups have been successfully sent!</span>, showClose:true};

	const [showSpinner, setShowSpinner] = useState({save: false, send: false, get: false});

	const [triggerSaveNow, setTriggerSaveNow] = useState(false);
	const [triggerSendNow, setTriggerSendNow] = useState(false);
	const [triggerGetExceptionsNow, setTriggerGetExceptionsNow] = useState(false);
	const [lastAutoSave, setLastAutoSave] = useState(-1);
	const [lastAutoGetExceptionCount, setLastAutoGetExceptionCount] = useState(-1);
	const [lastSignupRevision, setLastSignupRevision] = useState(-1);
	const [modalInfo, setModalInfo] = useState(MODAL_INIT);
	const [onModalCloseClearDocument, setOnModalCloseClearDocument] = useState(false);
	const [exceptionWaitingCount, setExceptionWaitingCount] = useState(0);

	const dispatch = useDispatch();

	let allSignupData = useSelector(state => selectAllSignupData(state));
	let editId = useSelector(state => selectEditId(state));
	let editMode = useSelector(state => selectEditMode(state));
	let credentials = useSelector(state => selectUserCredentials(state));
	let operator = useSelector(state => selectOperator(state));

	let isDirty = (allSignupData.revision !== lastSignupRevision);

	//console.log("allSignupData.revision: "+allSignupData.revision+":"+lastSignupRevision);

	const checkForSignupErrors = function(signups)
	{
		let errors = [];

		let formSeqNumbers = [];

		for(let i=0;i<signups.length;i++)
		{
			let signup = signups[i];

			if (signup.formInfo && signup.formInfo.formSeqNumber)
			{
				let formSeqNumber = -1;
				try
				{
					formSeqNumber = Number(signup.formInfo.formSeqNumber);
				}
				catch(err)
				{
					formSeqNumber = -1;
				}

				if (formSeqNumber <= 0 || isNaN(formSeqNumber))
				{
					errors.push("Signup #"+(i+1)+" Form ID ["+signup.formInfo.formSeqNumber+"] is invalid");
				}
				else
				{
					if (formSeqNumbers.includes(formSeqNumber))
					{
						errors.push("Signup #"+(i+1)+" Form ID ["+formSeqNumber+"] is used more than once");
					}
					else
					{
						formSeqNumbers.push(formSeqNumber);
					}
				}
			}
			else
			{
				errors.push("Signup #"+(i+1)+" Form ID is missing");
			}
		}

		return errors;
	}

	const doWork = async function()
	{
		let currTime = new Date().getTime();

		//console.log(currTime+":"+lastAutoSave+":"+AUTO_SAVE_TIME+":"+(currTime - lastAutoSave));

		if (triggerSendNow)
		{
			let sendSignupData = JSON.parse(JSON.stringify(allSignupData));

			let leftovers = [];

			if (editMode === EDITMODES.EXCEPTIONS)
			{
				for(let s=sendSignupData.signups.length-1;s>=0;s--)
				{
					if (!sendSignupData.signups[s].formInfo.merge)
					{
						leftovers.push(sendSignupData.signups[s]);
						sendSignupData.signups.splice(s, 1);
					}
				}
			}

			if (sendSignupData.signups.length > 0)
			{
				let errors = checkForSignupErrors(sendSignupData.signups);
				if (errors.length > 0)
				{
					let errorItems = [];
					for(let e=0;e<errors.length;e++)
					{
						errorItems.push(<li>{ errors[e] }</li>);
					}
					setModalInfo({showing: true, title:'Sending Signups', body:<Alert variant="danger"><ul>{ errorItems }</ul></Alert>, showClose:true, size: "lg"});
				}
				else
				{
					let dataEntryInfoCommand = { command: 'signupset-send', editMode: editMode, signupSetData: sendSignupData, editId: editId };
					let dataEntryInfoResponse = await callServer(dataEntryInfoCommand, credentials);
					console.log(JSON.stringify(dataEntryInfoResponse, null, 2));
			
					if (dataEntryInfoResponse.status >= 400 && dataEntryInfoResponse.status < 500)
					{
						dispatch(signOut({}));
						dispatch(setEditId({editId:undefined}));
						dispatch(setSelectedSignup({ value: 0 }));
						dispatch(resetSignups({}));
					}
					else if (dataEntryInfoResponse.status === 200)
					{
						if (leftovers.length > 0)
						{
							let createSignupData = { signups: leftovers };

							let dataEntryCreateCommand = { command: 'signupset-create', editMode: editMode, signupSetData: createSignupData, force: true };
							let dataEntryCreateResponse = await callServer(dataEntryCreateCommand, credentials);
							
							if (dataEntryCreateResponse.status >= 400 && dataEntryCreateResponse.status < 500)
							{
								dispatch(signOut({}));
								dispatch(setEditId({editId:undefined}));
								dispatch(setSelectedSignup({ value: 0 }));
								dispatch(resetSignups({}));
							}
							else
							{
								if (dataEntryCreateResponse.editId)
								{		
									dispatch(setAllSignupData({ operator: operator, signupSetData: createSignupData })); 
									dispatch(setEditId({value: dataEntryCreateResponse.editId}));
									dispatch(setSelectedSignup({ value: 0 }));
								}
							}
						}
						else
						{
							setOnModalCloseClearDocument(true);
						}

						setModalInfo({
							showing: true, 
							title:'Signups Successfully Sent', 
							body:<div>
								<Table>
									<thead>
										<tr>
											<th>Starting ID</th>
											<th>Ending ID</th>
											<th>Total</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{ sendSignupData.signups[0].formInfo.formSeqNumber }</td>
											<td>{ sendSignupData.signups[sendSignupData.signups.length-1].formInfo.formSeqNumber }</td>
											<td>{ sendSignupData.signups.length }</td>
										</tr>
									</tbody>
								</Table>
							</div>, 
							showClose:true
						});
					}
					else
					{
						let error = <span>Unknown Error</span>
						if (dataEntryInfoResponse.messages && dataEntryInfoResponse.messages.length > 0)
						{
							let errors = [];
							for(let i=0;i<dataEntryInfoResponse.messages.length;i++)
							{
								errors.push(<li key={ i }>{ dataEntryInfoResponse.messages[i] }</li>);
							}

							error = <ul>{ errors }</ul>
						}

						setModalInfo({showing: true, title:'Sending Signups', body:<Alert variant="danger">{ error }</Alert>, showClose:true, size: "lg"});
					}
				}
			}
			else
			{
				setModalInfo(MODAL_INIT);
			}

			setTriggerSaveNow(false);
			setTriggerSendNow(false);
			setShowSpinner({save: false, send:false, get:false});
		}
		else if (triggerGetExceptionsNow)
		{
			let dataEntryInfoCommand = { command: 'signupset-getnewexceptions', editMode: editMode, signupSetData: allSignupData, editId: editId, getCount: true };
			let dataEntryInfoResponse = await callServer(dataEntryInfoCommand, credentials);
			//console.log(JSON.stringify(dataEntryInfoResponse, null, 2));

			if (dataEntryInfoResponse)
			{
				if (dataEntryInfoResponse.status >= 400 && dataEntryInfoResponse.status < 500)
				{
					dispatch(signOut({}));
					dispatch(setEditId({editId:undefined}));
					dispatch(setSelectedSignup({ value: 0 }));
					dispatch(resetSignups({}));
				}
				else
				{
					if (dataEntryInfoResponse.status === 200)
					{
						if (dataEntryInfoResponse.count > 0)
						{
							let createSignupData = JSON.parse(JSON.stringify(allSignupData));
			
							let dataEntryCreateCommand = { command: 'signupset-create', editMode: editMode, signupSetData: createSignupData, force: true, getExceptions: true };
							let dataEntryCreateResponse = await callServer(dataEntryCreateCommand, credentials);

							//console.log(JSON.stringify(dataEntryCreateResponse, null, 2));

							if (dataEntryCreateResponse.status >= 400 && dataEntryCreateResponse.status < 500)
							{
								dispatch(signOut({}));
								dispatch(setEditId({editId:undefined}));
								dispatch(setSelectedSignup({ value: 0 }));
								dispatch(resetSignups({}));
							}
							else
							{
								if (dataEntryCreateResponse && 
									dataEntryCreateResponse.status === 200 && 
									dataEntryCreateResponse.editId && 
									dataEntryCreateResponse.signupSetData)
								{
									let signupSetDataObj = JSON.parse(dataEntryCreateResponse.signupSetData);

									if (signupSetDataObj)
									{
										let count = signupSetDataObj.signups.length - createSignupData.signups.length;

										for(let i=createSignupData.signups.length;i<signupSetDataObj.signups.length;i++)
										{
											let signup = signupSetDataObj.signups[i];
											if (signup.formInfo)
											{
												signup.formInfo[SIGNUPFIELDS.FIROUTINGNUMBERORIG] = (signup.formInfo.hasOwnProperty(SIGNUPFIELDS.FIROUTINGNUMBER)?signup.formInfo[SIGNUPFIELDS.FIROUTINGNUMBER]:undefined);
											}
										}

										dispatch(setAllSignupData({ operator: operator, signupSetData: signupSetDataObj })); 
			
										dispatch(setEditId({value: dataEntryCreateResponse.editId}));
										
										setExceptionWaitingCount(0);
			
										setModalInfo({
											showing: true, 
											title:'Exceptions Successfully Downloaded', 
											body: <span>{ count + " exceptions successfully downloaded." }</span>, 
											showClose:true
										});
									}
								}
								else
								{
									setModalInfo({showing: true, title:'Error Downloading Exceptions', body:<Alert variant="danger">{ dataEntryInfoResponse.message }</Alert>, showClose:true});
								}	
							}						
						}
						else
						{
							setModalInfo({
								showing: true, 
								title:'No New Exceptions', 
								body: <span>No new exceptions are available for download.</span>, 
								showClose:true
							});	
						}
					}
					else
					{
						setModalInfo({showing: true, title:'Error Downloading Exceptions', body:<Alert variant="danger">{ dataEntryInfoResponse.message }</Alert>, showClose:true});
					}
				}
			}
			setTriggerSaveNow(false);
			setTriggerGetExceptionsNow(false);
			setShowSpinner({save: false, send:false, get: false});
		}
		else if (triggerSaveNow || (lastAutoSave < 0 || (currTime - lastAutoSave) >= AUTO_SAVE_TIME))
		{
			let doSave = (lastAutoSave > 0 || triggerSaveNow) && !modalInfo.showing;

			console.log("Saving revision ["+doSave+"]: "+allSignupData.revision);

			if (doSave)
			{
				setShowSpinner({save: true, send:false, get: false});

				let dataEntryInfoCommand = { command: 'signupset-save', editMode: editMode, signupSetData: allSignupData, editId: editId };
				let dataEntryInfoResponse = await callServer(dataEntryInfoCommand, credentials);
		
				if (dataEntryInfoResponse.status >= 400 && dataEntryInfoResponse.status < 500)
				{
					dispatch(setEditId({ value: undefined }));
					dispatch(resetSignups({ operator: operator }));
					dispatch(setSelectedSignup({ value: 0 }));
					dispatch(signOut({}));
				}
				else if (dataEntryInfoResponse.status === 200)
				{
					console.log("Save successful!!!");
					setLastSignupRevision(allSignupData.revision);
				}
			}

			setLastAutoSave(new Date().getTime());
			setTriggerSaveNow(false);
			setTriggerSendNow(false);
			setShowSpinner({save: false, send:false, get: false});
		}
		else if ((lastAutoGetExceptionCount < 0 || (currTime - lastAutoGetExceptionCount) >= AUTO_GET_TIME))
		{
			console.log("lastAutoGetExceptionCount: get");

			if (editMode === EDITMODES.EXCEPTIONS)
			{
				let dataEntryInfoCommand = { command: 'signupset-getnewexceptions', editMode: editMode, signupSetData: allSignupData, editId: editId, getCount: true };
				let dataEntryInfoResponse = await callServer(dataEntryInfoCommand, credentials);
				if (dataEntryInfoResponse.status >= 400 && dataEntryInfoResponse.status < 500)
				{
					dispatch(setEditId({ value: undefined }));
					dispatch(resetSignups({ operator: operator }));
					dispatch(setSelectedSignup({ value: 0 }));
					dispatch(signOut({}));
				}
				else
				{
					if (dataEntryInfoResponse && dataEntryInfoResponse.status === 200)
					{
						setExceptionWaitingCount(dataEntryInfoResponse.count);
					}
				}
			}
			setLastAutoGetExceptionCount(new Date().getTime());
		}
	}

    const savedCallback = useRef(doWork);

    useEffect(() => {
	
		savedCallback.current = doWork;
	
	}, [lastAutoSave, lastAutoGetExceptionCount, exceptionWaitingCount, triggerSaveNow, triggerSendNow, triggerGetExceptionsNow, showSpinner, lastSignupRevision, modalInfo, onModalCloseClearDocument, allSignupData]);

	useEffect(() => {

		let id = undefined;
		let runNextIter = true;

		function tick()
		{
			savedCallback.current().then(
				() => {
					//console.log("useEffect.runNextIter: "+runNextIter);
					if (runNextIter)
					{
						id = setTimeout(tick, 500);
					}
				}
			);
		}

		console.log("SignupSaver::useEffect.start");

		id = setTimeout(tick, 500);

		return () => {
			console.log("SignupSaver::useEffect.cleanup");
			runNextIter = false;
			if (id)
			{
				console.log("SignupSaver::useEffect.clearTimeout");
				clearTimeout(id);
			}
		}
	}, []);

	const doModalClose = function()
	{
		setModalInfo(MODAL_INIT);

		if (onModalCloseClearDocument)
		{
			dispatch(setEditId({ value: undefined }));
			dispatch(resetSignups({ operator: operator }));
			dispatch(setSelectedSignup({ value: 0 }));
		}
	}
	
	return (
		<Form inline>
			<Modal size={ modalInfo.size?modalInfo.size:"lg" } show={ modalInfo.showing } onHide={ doModalClose }>
				<Modal.Header closeButton={ modalInfo.showClose }>
					<Modal.Title>{ modalInfo.title }</Modal.Title>
				</Modal.Header>
				<Modal.Body>{ modalInfo.body }</Modal.Body>
				<Modal.Footer>
					{ modalInfo.showClose ?
						<Button variant="secondary" onClick={ doModalClose }>Close</Button>
						:
						<span>&nbsp;</span>
					}
				</Modal.Footer>
			</Modal>
			<Button disabled={ !isDirty } size="sm" variant="primary" onClick={ async () => {

				setShowSpinner({save: true, send:false, get: false});
				setTriggerSaveNow(true);
				}
			}>
				<span>Save Signups</span>
				<span>
					{ showSpinner.save && 
						<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 							
					}
				</span>
			</Button>
			<div className="navbar-form-button-spacer"></div>
			<Button size="sm" variant="primary" onClick={ async () => {

				let progress = { ...MODAL_PROGRESS };

				progress.title = "Sending Signups..."

				setShowSpinner({save: false, send:true, get: false});
				setModalInfo(progress);
				setTriggerSendNow(true);
				}
			}>
				<span>Send Signups</span>
				<span>
					{ showSpinner.send && 
						<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 							
					}
				</span>
			</Button>
			{ editMode === EDITMODES.EXCEPTIONS && 
				<>
					<div className="navbar-form-button-spacer"></div>
					<Button size="sm" variant="primary" onClick={ async () => {

						let progress = { ...MODAL_PROGRESS };

						progress.title = "Getting Exceptions..."

						setShowSpinner({save: false, send:false, get: true});
						setModalInfo(progress);
						setTriggerGetExceptionsNow(true);
						}
					}>
						<span>Get Exceptions</span>
						{
							exceptionWaitingCount > 0 &&
							<span>&nbsp;&nbsp;<Badge variant="light">{ exceptionWaitingCount }</Badge></span>
						}
						<span>
							{ showSpinner.get && 
								<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 							
							}
						</span>
					</Button>
				</>
			}
		</Form>
	);
}

export default SignupSaver;
