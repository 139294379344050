const crypto = require('crypto');
const fetch = require('node-fetch');

const util = require('util');
const zlib = require('zlib');
const deflate = util.promisify(zlib.deflate);

export function addAuthorizationHeaders(headers, path, dataStr, username, passwordHash)
{
	let date = new Date();
	let dateStr = date.toUTCString();
	let xdateStr = date.getTime();
	let digest = crypto.createHash('sha256').update(dataStr, 'utf8').digest().toString('hex');
	let keyStr = crypto.createHash('sha256').update(username+':'+passwordHash, 'utf8').digest().toString('hex');
	let nonce = crypto.randomBytes(16).toString('hex');

	let signatureSource = keyStr+':'+nonce+':'+path+":"+xdateStr+':'+digest;

	let signature = crypto.createHash('sha256').update(signatureSource, 'utf8').digest().toString('hex');

	headers['Date'] = dateStr;
	headers['X-Date'] = xdateStr;
	headers['Digest'] = 'SHA-256='+digest;
	headers['Authorization'] = 'Signature '+'keyId='+username+',nonce='+nonce+',algorithm=rsa-sha256'+',headers="(request-target) x-date digest"'+',signature='+signature
}

export function hashPassword(password)
{
	return crypto.createHash('sha1').update(password, 'utf8').digest().toString('base64');
}

export async function loadLocalFile(path)
{
	let ret = undefined;

	try
	{
		let response = await fetch(path);
		if (response)
		{
			ret = response.text();
		}
	}
	catch(err)
	{
		console.log("loadLocalFile["+path+"]: "+err.toString());
	}

	return ret;
}

export async function callServer(data, credentials)
{
	let responseObj = { status: 500 };

	let url = window.runConfig.apiBaseURL;
	let path = window.runConfig.apiBasePath + data.command;

	try
	{
		let allDataStr = JSON.stringify(data);
		let allDataBuffer = await deflate(allDataStr);

		//console.log("callServer ["+data.command+"]: content-length: "+allDataBuffer.length);

		let headers = {
			'Content-Type': 'application/json',
			'Content-Encoding': 'deflate',
		};

		if (credentials.jwt)
		{
			headers.Authorization = 'Bearer '+credentials.jwt;
		}
		else if (credentials.username && credentials.password)
		{
			headers.Authorization = 'Basic '+Buffer.from(credentials.username+":"+credentials.password).toString('base64');
		}
		
		//addAuthorizationHeaders(headers, path, allDataStr, credentials.username, credentials.passwordHash);

		//console.log(JSON.stringify(headers, null, 2));

		let response = await fetch(url+path, {
			method: 'post',
			compress: true,
			headers: headers,
			body: allDataBuffer
		});
		
		responseObj = await response.json();
	}
	catch(err)
	{
		console.error("syncSignups[getData]: "+err.toString());
		console.error(err.stack);

		/*
		{
			"message": "request to http://localhost:3002/ failed, reason: connect ECONNREFUSED 127.0.0.1:3002",
			"type": "system",
			"errno": "ECONNREFUSED",
			"code": "ECONNREFUSED"
		}		
		*/

		if (err.code === "ECONNREFUSED")
			responseObj.message = "Remote API connection error (ECONNREFUSED). API server is most likely down. Try again later: url: "+url+path;
		else
			responseObj.message = err.toString();
	}
	
	return responseObj;
}
