import { rtAddHyphens } from './SignupUtils';

export function setDataEntryInfo(dataEntryInfo)
{
	dataEntryInfo.headerNotes = [];
	dataEntryInfo.detailNotes = [];
	dataEntryInfo.companyMap = {};
	dataEntryInfo.fiNames = [];
	dataEntryInfo.fiNameMap = {};

	try
	{
		let errorcodes = dataEntryInfo.errorcodes;
		for(let d=0;d<errorcodes.data.length;d++)
		{
			let data = errorcodes.data[d];

			let source = {
				note: data[errorcodes.fieldMap["ERRORCODE"]],
			}

			if (data[errorcodes.fieldMap["IsHeader"]])
			{
				dataEntryInfo.headerNotes.push(source);
			}

			if (data[errorcodes.fieldMap["IsDetail"]])
			{
				dataEntryInfo.detailNotes.push(source);
			}
		}

		//console.log("detailNotes\n"+JSON.stringify(dataEntryInfo.detailNotes, null, 2));
		//console.log("headerNotes\n"+JSON.stringify(dataEntryInfo.headerNotes, null, 2));
	}
	catch(err)
	{
		console.log("setDataEntryInfo[errorcodes]: "+err.toString());
	}

	try
	{
		let companies = dataEntryInfo.company;
		for(let d=0;d<companies.data.length;d++)
		{
			let data = companies.data[d];

			let company = {
				id: data[companies.fieldMap["COMPANYNUM"]],
				name: data[companies.fieldMap["COMPANYNAME"]],
				status: data[companies.fieldMap["STATUS"]],
				acctNumDesc: data[companies.fieldMap["AccountNumDesc"]],
				formats: []
			};

			dataEntryInfo.companyMap[company.id] = company;
		}

		let billeracctnumformats = dataEntryInfo.billeracctnumformats;
		for(let d=0;d<billeracctnumformats.data.length;d++)
		{
			let data = billeracctnumformats.data[d];

			let id = data[billeracctnumformats.fieldMap["BillerID"]];

			let company = dataEntryInfo.companyMap[id];
			if (company)
			{
				let format = {
					totalDigits: data[billeracctnumformats.fieldMap["TotalDigits"]],
					format: data[billeracctnumformats.fieldMap["Format"]],
				}

				company.formats.push(format);
			}
		}

		//console.log("companyMap\n"+JSON.stringify(dataEntryInfo.companyMap, null, 2));
	}
	catch(err)
	{
		console.log("setDataEntryInfo[companies]: "+err.toString());
	}

	try
	{
		let fiMap = dataEntryInfo.finames.fieldMap;

		for(let d=0;d<dataEntryInfo.finames.data.length;d++)
		{
			let data = dataEntryInfo.finames.data[d];

			let finame = {
				inputRT: data[fiMap["INPUTRT"]],
				useRT: data[fiMap["USERT"]],
				name: data[fiMap["FINAME"]],
				city: data[fiMap["FICITY"]],
				state: data[fiMap["FISTATE"]],
			};
			
			dataEntryInfo.fiNames.push(finame);

			dataEntryInfo.fiNameMap[finame.inputRT] = finame;
		}	
	}
	catch(err)
	{
		console.log("setDataEntryInfo[finames]: "+err.toString());
	}


	global.dataEntryInfo = dataEntryInfo;
}

export function lookupFINames(routingNumber)
{
	return global.dataEntryInfo.fiNameMap[rtAddHyphens(routingNumber)];
}

export function isFIName(routingNumber)
{
	return global.dataEntryInfo.fiNameMap.hasOwnProperty(rtAddHyphens(routingNumber));
}

export function getFINames(sortKey)
{
	//states.sort((a, b) => (a.name > b.name) ? 1 : -1)
 
	return global.dataEntryInfo.fiNames;
}

export function getRandomCompany()
{
	let res = undefined;

	try
	{
		let randomVal = getRandomInt(0, global.dataEntryInfo.company.data.length-1);
		let data = global.dataEntryInfo.company.data[randomVal];
		res = {
			companyNum: data[global.dataEntryInfo.company.fieldMap["COMPANYNUM"]]
		}
	}
	catch(err)
	{
		console.log("getRandomCompany: "+err.toString());
	}

	return res;
}

export function getRandomRT()
{
	let res = undefined;

	try
	{
		let randomVal = getRandomInt(0, global.dataEntryInfo.finames.data.length-1);
		let data = global.dataEntryInfo.finames.data[randomVal];
		res = {
			rt: data[global.dataEntryInfo.finames.fieldMap["INPUTRT"]],
		}
	
	}
	catch(err)
	{
		console.log("getRandomRT: "+err.toString());
	}

	return res;
}

export function lookupZipcode(zipcode)
{
	let res = undefined;

	try
	{
		let primary = global.dataEntryInfo.zipcodes.keys.primary;
		if (primary && primary.hasOwnProperty(zipcode))
		{
			let data = global.dataEntryInfo.zipcodes.data[primary[zipcode]];

			res = {
				zipcode: zipcode,
				city: data[global.dataEntryInfo.zipcodes.fieldMap["City"]],
				state: data[global.dataEntryInfo.zipcodes.fieldMap["State"]],
			}
		}
	}
	catch(err)
	{
		console.log("lookupZipcode: "+err.toString());
	}

	return res;
}

function getRandomInt(min, max) 
{
	min = Math.ceil(min);
	max = Math.floor(max);

	return Math.floor(Math.random() * (max - min) + min);
}

export function getRandomZipCode()
{
	let res = undefined;

	try
	{
		let randomVal = getRandomInt(0, global.dataEntryInfo.zipcodes.data.length-1);
		let data = global.dataEntryInfo.zipcodes.data[randomVal];
		res = {
			zipcode: data[global.dataEntryInfo.zipcodes.fieldMap["ZipCode"]],
			city: data[global.dataEntryInfo.zipcodes.fieldMap["City"]],
			state: data[global.dataEntryInfo.zipcodes.fieldMap["State"]],
		}
	
	}
	catch(err)
	{
		console.log("getRandomZipCode: "+err.toString());
	}

	return res;
}

export function getFormSource()
{
	let res = [];

	try
	{
		let formssource = global.dataEntryInfo.formssource;
		for(let d=0;d<formssource.data.length;d++)
		{
			let data = formssource.data[d];

			let source = {
				key: data[formssource.fieldMap["BILLER"]],
				name: data[formssource.fieldMap["FORMSSOURCE"]]
			}

			res.push(source);
		}
	}
	catch(err)
	{
		console.log("getFormSource: "+err.toString());
	}

	return res;

}

export function getHeaderNotes()
{
	return global.dataEntryInfo.headerNotes;
}

export function getDetailNotes()
{
	return global.dataEntryInfo.detailNotes;
}

export function getCompanyInfo(id)
{
	return global.dataEntryInfo.companyMap[id];
}

export function isCompanyAcctNumValid(companyInfo, value)
{
	let isMatch = false;
	
	try
	{
		for(let f=0;f<companyInfo.formats.length;f++)
		{
			let format = companyInfo.formats[f];

			let regExStr = "^";

			let inBracket = false;

			for (let c of format.format) 
			{
				if (inBracket)
				{
					regExStr += c;
					if (c === ']')
					{
						inBracket = false;
					}
				}
				else if (c === '#')
				{
					regExStr += "[0-9]";
				}
				else if (c === '&')
				{
					regExStr += "[a-zA-Z]";
				}
				else if (c === '@')
				{
					regExStr += "[0-9a-zA-Z]";
				}
				else if (c === '[')
				{
					regExStr += c;
					inBracket = true;
				}
				else
				{
					regExStr += '['+c+']';
				}
			}

			regExStr += "$";

			//console.log(format.format);
			//console.log(regExStr);

			const regex = RegExp(regExStr);

			if (regex.test(value))
			{
				isMatch = true;
				break;
			}
		}
	}
	catch(err)
	{
		console.log("isCompanyAcctNumValid["+value+"]: "+err.toString());
	}

	return isMatch;
}