import { createSlice } from '@reduxjs/toolkit';

export const selectedSignupSlice = createSlice({
	name: 'selectedSignup',
	initialState: {
        value: 0
    },
	reducers: {
		setSelectedSignup: (state, action) => {
			state.value = action.payload.value;
		},
		incSelectedSignup: (state, action) => {
			state.value += 1;
		},
		decSelectedSignup: (state, action) => {
			state.value -= 1;
			if (state.value < 0)
				state.value = 0;
		}
	},
});

export const selectSelectedSignup = (state) => {
	return state.selectedSignup.value;
}

export const { 
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup
} = selectedSignupSlice.actions;

export default selectedSignupSlice.reducer;
