import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { Alert, Button, Row, Col, Form, Card, Spinner, InputGroup } from 'react-bootstrap';

import { callServer, hashPassword } from '../utils/APIUtils';
import { rtAddHyphens } from '../utils/SignupUtils';
import { useCookies } from 'react-cookie';
import ChangePassword from '../controls/ChangePassword';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/fontawesome-free-solid'

import {
	setUser,
	setUserInfo,
	signOut,
	selectOperator,
 } from '../store/userSlice';

import {
	setDataEntryInfo,
} from '../utils/DataEntryInfoUtils';

import {
	loadLocalFile,
} from '../utils/APIUtils';

import {
	decryptSignup,
} from '../utils/DebugUtils';

import {
	setEditId,
	setEditMode,
	selectEditId,
	selectEditMode,
	EDITMODES,
} from '../store/editIdSlice';

import {
	addSignup,
	removeSignup,
	resetSignups,
	setField,
	setBillerInfo,
	reset,
	selectField,
	selectBillerInfo,
	selectSignupCount,
	selectAllSignupData,
	SIGNUPFIELDS,
	BILLERINFOFIELDS,
	setAllSignupData,
	DEFAULT_OPERATOR,
} from '../store/signupsSlice';

import {
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup,
	selectSelectedSignup,
} from '../store/selectedSignupSlice';

function SignIn(props)
{
	const [cookies, setCookie] = useCookies(['signinShowEditMode']);

	const [userUsername, setUserUsername] = useState('');
	const [userPassword, setUserPassword] = useState('');
	const [signinError, setSigninError] = useState(undefined);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [editModeLocal, setEditModeLocal] = useState(EDITMODES.DATAENTRY);
	const [signInEffect, setSignInEffect] = useState(false);
	const [showEditMode, setShowEditMode] = useState(false);
	const [showChangePassword, setShowChangePassword] = useState(false);
	const [userPasswordNew, setUserPasswordNew] = useState('');
	const [userPasswordNewValid, setUserPasswordNewValid] = useState(false);

	const dispatch = useDispatch();

	let operator = useSelector(state => selectOperator(state));

	useEffect(() => {

		let signinShowEditMode = cookies.signinShowEditMode || false;

		if (signinShowEditMode)
		{
			setShowEditMode(true);
		}

		return () => {
		}

	}, []);

	const doSignInRemote = async function()
	{
		let signInSuccessful = false;
		let signinCommand = { command: 'user-signin' };

		if (showChangePassword)
		{
			signinCommand.newPassword = userPasswordNew;
		}

		let userUsernameUpper = userUsername.toLocaleUpperCase('en-US');
		let credentials = { username: userUsernameUpper, password: userPassword };
		let editModeInside = editModeLocal;

		let signinResponse = await callServer(signinCommand, credentials);

		if (signinResponse.status === 200)
		{
			//console.log(JSON.stringify(signinResponse, null, 2));

			credentials = {
				jwt: signinResponse.jwt
			};

			setSigninError(undefined);

			setCookie('signinShowEditMode', (signinResponse.userInfo.access === 'admin'), { path: '/', maxAge: 31536000 });

			if (signinResponse.userInfo.access === 'admin' && !showEditMode)
			{
				setShowEditMode(true);
			}
			else
			{
				if (editModeInside === EDITMODES.EXCEPTIONS && signinResponse.userInfo.access !== 'admin')
				{
					editModeInside = EDITMODES.DATAENTRY;
				}
	
				let dataEntryInfoCommand = { command: 'dataentryinfo-get' };
				let dataEntryInfoResponse = await callServer(dataEntryInfoCommand, credentials);
				
				if (dataEntryInfoResponse.status == 200)
				{
					setDataEntryInfo(dataEntryInfoResponse.dataEntryInfo);
	
					dispatch(setSelectedSignup({ value: 0 }));

					let signupSetLoadCommand = { command: 'signupset-load', editMode: editModeInside };
					let signupSetLoadResponse = await callServer(signupSetLoadCommand, credentials);
		
					let editId = undefined;
					
					if (signupSetLoadResponse && signupSetLoadResponse.status == 200)
					{
						if (signupSetLoadResponse.signupSetData)
						{
							dispatch(setAllSignupData({ signupSetData: JSON.parse(signupSetLoadResponse.signupSetData), operator: operator }));
						}
		
						editId = signupSetLoadResponse.editId;
					}
					else
					{
						dispatch(resetSignups({ operator: operator }));
					}
	
					dispatch(setUser({ username: userUsernameUpper, jwt: signinResponse.jwt, userInfo: signinResponse.userInfo }));
											
					dispatch(setEditMode({ value: editModeInside }));
	
					dispatch(setEditId({value: editId}));

					signInSuccessful = true;
				}
				else
				{
					if (dataEntryInfoResponse.message)
					{
						setSigninError("Get dataentryinfo error: "+dataEntryInfoResponse.message);
					}
					else
					{
						setSigninError("Get dataentryinfo error: Unknown");
					}
				}
			}
		}
		else if (signinResponse.status == 418)
		{
			setSigninError("Your password has expired, please enter a new password.");
			setShowChangePassword(true);	
			
			if (signinResponse.userInfo)
			{
				setCookie('signinShowEditMode', (signinResponse.userInfo.access === 'admin'), { path: '/', maxAge: 31536000 });
				if (signinResponse.userInfo.access === 'admin' && !showEditMode)
				{
					setShowEditMode(true);
				}	
			}
		}
		else if (signinResponse.status == 419)
		{
			setSigninError("Your new password does not conform to the password rules.");
			setShowChangePassword(true);	
		}
		else if (signinResponse.status == 420)
		{
			setSigninError("Your new password has been recently used.");
			setShowChangePassword(true);	
		}
		else if (signinResponse.status == 421)
		{
			setSigninError("Error updating password, try again later.");
			setShowChangePassword(true);	
		}
		else if (signinResponse.status == 422)
		{
			let hours = Math.floor(signinResponse.lockTimeRemaining/60);
			let minutes = signinResponse.lockTimeRemaining%60;

			let timeStr = '';
			if (hours > 1)
			{
				timeStr += hours+' hours';
			}
			else if (hours === 1)
			{
				timeStr += hours+' hour';
			}

			if (minutes > 1)
			{
				if (timeStr.length > 0)
				{
					timeStr += ' ';
				}
				timeStr += minutes+' minutes';
			}
			else if (minutes === 1)
			{
				if (timeStr.length > 0)
				{
					timeStr += ' ';
				}
				timeStr += minutes+' minute';
			}

			setSigninError("Too many failed sign-in attempts, your account will be locked for "+timeStr+".");
		}
		else if (signinResponse.status >= 400 && signinResponse.status < 500)
		{
			setSigninError("Username or Password is incorrect");
		}
		else if (signinResponse.status >= 400 && signinResponse.status < 500)
		{
			setSigninError("Username or Password is incorrect");
		}
		else
		{
			if (signinResponse.message)
			{
				setSigninError("User sign-in error: "+signinResponse.message);
			}
			else
			{
				setSigninError("User sign-in error: Unknown");
			}
		}
	
		if (!signInSuccessful)
		{
			setShowSpinner(false);
		}
	}

	useEffect(() => {

		if (signInEffect)
		{
			setSignInEffect(false);
			doSignInRemote();
		}
		
		return () => {
		}

	}, [signInEffect]);

	const doSignIn = function()
	{
		setShowSpinner(true);
		setSignInEffect(true);
	}

	useHotkeys('enter', () => doSignIn(), {enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT']})

	let signinButtonActive = userUsername.trim().length > 0 && userPassword.trim().length > 0 && (!showChangePassword || userPasswordNewValid);

	return (
		<div>
			<Row>
				<Col xs="2"></Col>
				<Col className="signin-col" xs="8">
					{
						signinError && 
						<Alert variant="danger">{ signinError }</Alert>
					}
					<Card className="signin-card">
						<Card.Header>{ showChangePassword?"Change Password":"Sign In" }</Card.Header>
						<Card.Body>
							{ !showChangePassword &&
								<>
									<Form.Group controlId="userUsername">
										<Form.Label className="credentials-label">User Name</Form.Label>
										<Form.Control 
												autoFocus
												type="text" 
												name="userUsername"
												onChange={ (evt) => { setUserUsername(evt.target.value) } }
												value={ userUsername }
												/>
									</Form.Group>
									<Form.Group controlId="userPassword">
										<Form.Label className="credentials-label">Password</Form.Label>
										<InputGroup>
											<Form.Control 
												type={ showPassword?"text":"password" }
												name="userPassword"
												onChange={ (evt) => { setUserPassword(evt.target.value) } }
												value={ userPassword }
												/>
											<InputGroup.Append>
												<Button variant="primary-outline" tabIndex="-1" onClick={
													() => {
														setShowPassword(!showPassword);
													}
												}><FontAwesomeIcon icon={ showPassword?faEyeSlash:faEye } /></Button>
											</InputGroup.Append>
										</InputGroup>
									</Form.Group>
								</>
							}
							{
								showChangePassword &&
								<ChangePassword badWords={ [userUsername] } onChange={ (password, valid) => {
									setUserPasswordNew(password);
									setUserPasswordNewValid(valid);
								} }/>
							}
							{ showEditMode &&
								<Form.Group controlId="editMode">
									<Form.Label className="credentials-label">Edit Mode</Form.Label>
									<div className="edit-mode-checkboxes">
											<Form.Check
												type="radio"
												label="Data Entry"
												key={ 'editmodes_' + EDITMODES.DATAENTRY } 
												id={ 'editmodes_' + EDITMODES.DATAENTRY }
												value={ EDITMODES.DATAENTRY }
												checked={ editModeLocal === EDITMODES.DATAENTRY }
												onChange={ (evt) => { setEditModeLocal(evt.target.value) } }
												/>
											<Form.Check
												type="radio"
												label="Exceptions"
												key={ 'editmodes_' + EDITMODES.EXCEPTIONS } 
												id={ 'editmodes_' + EDITMODES.EXCEPTIONS }
												value={ EDITMODES.EXCEPTIONS }
												checked={ editModeLocal === EDITMODES.EXCEPTIONS }
												onChange={ (evt) => { setEditModeLocal(evt.target.value) } }
												/>
									</div>
								</Form.Group>
							}
							<div className="signin-button text-right">
								<Button disabled={ !signinButtonActive } onClick={  async (evt) =>  { 
									doSignIn();
									}}>
										<span>{ showChangePassword?"Change Password & Sign In":"Sign In" }</span>
										<span>
											{ showSpinner && 
												<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 							
											}
										</span>
									</Button>
							</div>
							<span><small><i>{ window.buildVersion?"Version: "+window.buildVersion.version:"" }</i></small></span>
						</Card.Body>
					</Card>
				</Col>
				<Col xs="2"></Col>
			</Row>
		</div>
	);
}

export default SignIn;
