import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Row, Col, Form, Table, OverlayTrigger, Tooltip, InputGroup, DropdownButton, Dropdown, Popover, Badge, Modal } from 'react-bootstrap';

import FieldValid from './FieldValid';

import { skipToNextFocus, skipToFirstFocus } from '../utils/DOMUtils';
import { rtAddHyphens, rtRemoveHyphens } from '../utils/SignupUtils';

import {
	setField,
	setBillerInfo,
	reset,
	addSignup,
	removeSignup,
	selectField,
	selectBillerInfo,
	selectSignupCount,
	SIGNUPFIELDS,
	BILLERINFOFIELDS,
} from '../store/signupsSlice';

import {
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup,
	selectSelectedSignup,
} from '../store/selectedSignupSlice';

import {
	lookupZipcode,
	lookupFINames,
	isFIName,
	getFormSource,
	getHeaderNotes,
	getDetailNotes,
	getCompanyInfo,
	isCompanyAcctNumValid,
} from '../utils/DataEntryInfoUtils';

function SignupFormField(props) 
{
	const dispatch = useDispatch();

	let selectedSignup = props.selectedSignup;
	
	let fieldValueRaw = useSelector(state => selectField(state, selectedSignup, props.fieldId));
	let fieldValue = fieldValueRaw || '';
	
	let style = {};

	if (props.maxLength <= 2)
		style.width = 80;
	else if (props.maxLength <= 10)
		style.width = 160;

	let typeStr = "text";
	if (props.fieldId === SIGNUPFIELDS.EMAILADDRESS)
	{
		typeStr = "email";
	}

	let showFieldValid = false;
	let fieldValid = 0;
	switch(props.fieldId)
	{
		case SIGNUPFIELDS.ZIP:
			showFieldValid = true;

			if (fieldValue && fieldValue.length > 0)
			{
				let lookup = (fieldValue.length == 5)?lookupZipcode(fieldValue):undefined;
				fieldValid = lookup?1:-1;
			}
			break;
		case SIGNUPFIELDS.FIROUTINGNUMBER:
			showFieldValid = true;
			if (fieldValue && fieldValue.length > 0)
			{
				fieldValid = ((rtRemoveHyphens(fieldValue) >= 9)?isFIName(fieldValue):false)?1:-1;
			}
			break;
	}
	
	if (props.fieldId === SIGNUPFIELDS.FORMSOURCE)
	{
		let formSourceArr = getFormSource();

		return (
			<Form.Control 
				as="select" 
				key={ props.fieldId+":"+selectedSignup+'_ctrl' } 
				size="sm" 
				value={ fieldValue }
				tabIndex={ props.tabIndex }
				onChange={e => {
	
					let value = e.target.value;
	
					dispatch(setField({ signup: selectedSignup, field: props.fieldId, value: value }));	
				}
				}
				>
			{
				formSourceArr.map((item, index) => {
					return <option key={ item.key } value={ item.key }>{ item.key + " - " + item.name }</option>
				})
			}
			</Form.Control>
		);
	}

	if (props.fieldId === SIGNUPFIELDS.FIACCONTTYPECHECKING)
	{
		let accountType = [{key:"C", name:"Checking"}, {key:"S", name:"Savings"}];

		return (
			<Form.Control 
				as="select" 
				key={ props.fieldId+":"+selectedSignup+'_ctrl' } 
				size="sm" 
				value={ fieldValue?"C":"S" }
				tabIndex={ props.tabIndex }
				onChange={e => {
	
					let value = (e.target.value==="C");
	
					dispatch(setField({ signup: selectedSignup, field: props.fieldId, value: value }));	
				}
				}
				>
			{
				accountType.map((item, index) => {
					return <option key={ item.key } value={ item.key }>{ item.key + " - " + item.name }</option>
				})
			}
			</Form.Control>
		);

	}

	if (props.fieldId === SIGNUPFIELDS.NOTE)
	{
		let notesArr = getHeaderNotes();

		return (
			<InputGroup>
				<Form.Control 
					as="input" 
					key={ props.fieldId+":"+selectedSignup+'_ctrl' }
					size="sm" 
					type={ typeStr }
					value={ fieldValue }
					tabIndex={ props.tabIndex }
					onChange={e => {

						let value = e.target.value;

						dispatch(setField({ signup: selectedSignup, field: props.fieldId, value: value }));
					}
					}
					maxLength={ props.maxLength }
					style={ style }
					/>

				<DropdownButton
					as={ InputGroup.Append }
					size="sm" 
					variant="outline-secondary"
					tabIndex={ props.tabIndex }
					key={ props.fieldId+":"+selectedSignup+'_dropdown' }
					title=""
					onFocus={ (e) => { skipToNextFocus(e) } }
				>
					{
						notesArr.map((item, index) => {
							return <Dropdown.Item key={ index } eventKey={ item.note } onSelect={
								(e) => {
									dispatch(setField({ signup: selectedSignup, field: props.fieldId, value: e }));
								}
							}>{ item.note }</Dropdown.Item>
						})
					}
				</DropdownButton>
			</InputGroup>
		);
	}

	if (props.fieldId === SIGNUPFIELDS.COMPLETE)
	{
		return (
			<Form.Check 
				className="signup-checkbox" 
				type="checkbox" 
				checked={ fieldValue }
				tabIndex={ props.tabIndex }
				key={ props.fieldId+":"+selectedSignup }
				onChange={e => dispatch(setField({ signup: selectedSignup, field: props.fieldId, value: e.target.checked })) }
				label={ props.fieldLabel } />
		);
	}

	if (props.fieldId === SIGNUPFIELDS.MERGE)
	{
		if (fieldValueRaw === '' || fieldValueRaw === undefined || fieldValueRaw === null)
		{
			fieldValueRaw = true;
		}

		return (
			<Form.Check 
				className="signup-checkbox" 
				type="checkbox" 
				checked={ fieldValueRaw }
				tabIndex={ props.tabIndex }
				key={ props.fieldId+":"+selectedSignup }
				onChange={e => {
					dispatch(setField({ signup: selectedSignup, field: props.fieldId, value: e.target.checked }));
				}}
				label={ props.fieldLabel } />
		);
	}

	if (props.fieldId === SIGNUPFIELDS.ERRORS)
	{
		if (fieldValueRaw === '' || fieldValueRaw === undefined || fieldValueRaw === null)
		{
			fieldValueRaw = [];
		}

		return (
			<span className="signup-single-errors-span">
			{
				fieldValueRaw.length <= 0 ?
				<span className="signup-single-noerrors"><i>No Errors</i></span>
				:
				<ul className="signup-single-errors-ul">
				{ fieldValueRaw.map((item, index) => {
					return <li>{ item }</li>
					})
				}
				</ul>
			}
			</span>
		);
	}

	return (
		<InputGroup style={ style }>
			<Form.Control 
				as="input" 
				key={ props.fieldId+":"+selectedSignup+'_ctrl' }
				size="sm" 
				type={ typeStr }
				value={ fieldValue }
				maxLength={ props.maxLength }
				tabIndex={ props.tabIndex }
				onChange={e => {

					let value = e.target.value;

					dispatch(setField({ signup: selectedSignup, field: props.fieldId, value: value }));

					value = value.trim();
					switch(props.fieldId)
					{
					case SIGNUPFIELDS.ZIP:
						if (value && value.length == 5)
						{
							let lookup = lookupZipcode(value);
							if (lookup)
							{
								dispatch(setField({ signup: selectedSignup, field: SIGNUPFIELDS.CITY, value: lookup.city.toUpperCase() }));
								dispatch(setField({ signup: selectedSignup, field: SIGNUPFIELDS.STATE, value: lookup.state.toUpperCase() }));
							}
						}
						break;
					}	
				}
				}
				/>
				{ showFieldValid &&
					<FieldValid fieldValid={ fieldValid } />
				}
			</InputGroup>
	);
}

export default SignupFormField;