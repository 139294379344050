import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { Button, Row, Col, Form, Table, OverlayTrigger, Tooltip, InputGroup, DropdownButton, Dropdown, Popover, Badge, Modal } from 'react-bootstrap';

import SignupNavigator from '../controls/SignupNavigator';
import FieldValid from '../controls/FieldValid';
import SignupFormField from '../controls/SignupFormField';
import SignupBillerFormField from '../controls/SignupBillerFormField';
import ZeroSignups from '../controls/ZeroSignups';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faCheckCircle, faCircle } from '@fortawesome/fontawesome-free-solid'
import { rtAddHyphens, rtRemoveHyphens } from '../utils/SignupUtils';

import { callServer, hashPassword } from '../utils/APIUtils';

import {
	setEditId,
	selectEditId,
	selectEditMode,
	EDITMODES,
} from '../store/editIdSlice';

import {
	lookupZipcode,
	lookupFINames,
	getFormSource,
	getHeaderNotes,
	getDetailNotes,
	getCompanyInfo,
	isCompanyAcctNumValid,
	getRandomZipCode,
	getRandomRT,
	getRandomCompany,
} from '../utils/DataEntryInfoUtils';

import { skipToNextFocus, skipToFirstFocus } from '../utils/DOMUtils';

import {
	setField,
	setBillerInfo,
	reset,
	addSignup,
	removeSignup,
	selectField,
	selectBillerInfo,
	selectSignupCount,
	selectAllSignupData,
	SIGNUPFIELDS,
	BILLERINFOFIELDS,
	getInitialStateSignup
} from '../store/signupsSlice';

import {
	selectOperator,
	selectUserInfo
} from '../store/userSlice';

import {
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup,
	selectSelectedSignup,
} from '../store/selectedSignupSlice';

const faker = require('faker');
const dateFormat = require("dateformat");

const MAX_BILLER_ROWS = 15;
const FORMLABELWIDTH = 3;

function InputText(props) 
{	
	let selectedSignup = useSelector(selectSelectedSignup);

	return (
		<Form.Group className="editpage-form-group" as={Row}>
			<Form.Label className="editpage-form-label" column sm={ FORMLABELWIDTH }>{ props.fieldLabel }</Form.Label>
			<Col sm={ 12 - FORMLABELWIDTH }>
				<SignupFormField fieldId={ props.fieldId } tabIndex={ props.tabIndex } fieldLabel={ props.fieldLabel } maxLength={ props.maxLength } selectedSignup={ selectedSignup } />
			</Col>
		</Form.Group>
	);
}

function InputCheckbox(props) {

	const dispatch = useDispatch();

	let selectedSignup = useSelector(selectSelectedSignup);
	let fieldValue = useSelector(state => selectField(state, selectedSignup, props.fieldId)) || '';
	
	return (
		<Form.Group className="editpage-form-group" as={Row}>
			<Form.Label className="editpage-form-label" column sm={ FORMLABELWIDTH }>&nbsp;</Form.Label>
			<Col sm={ 12 - FORMLABELWIDTH }>
				<Form.Check 
					className="signup-checkbox" 
					type="checkbox" 
					checked={ fieldValue }
					tabIndex={ props.tabIndex }
					onChange={e => dispatch(setField({ signup: selectedSignup, field: props.fieldId, value: e.target.checked })) }
					label={ props.fieldLabel } />
			</Col>
		</Form.Group>
	);
}


function BillerRow(props) 
{	
	let selectedSignup = useSelector(selectSelectedSignup);
	let billerId = useSelector(state => selectBillerInfo(state, selectedSignup, props.id, BILLERINFOFIELDS.BILLERID)) || '';
	let acctNum = useSelector(state => selectBillerInfo(state, selectedSignup, props.id, BILLERINFOFIELDS.ACCOUNTNUMBER)) || '';
	let note = useSelector(state => selectBillerInfo(state, selectedSignup, props.id, BILLERINFOFIELDS.NOTE)) || '';
	let complete = useSelector(state => selectBillerInfo(state, selectedSignup, props.id, BILLERINFOFIELDS.COMPLETE));

	if (complete === undefined)
	{
		complete = true;
	}

	let companyInfo = getCompanyInfo(billerId);

	let title = <span>&nbsp;</span>;
	if (companyInfo)
	{
		title = <span>{ companyInfo.name }</span>;
	}
	else if (billerId.length > 0)
	{
		title = <span><Badge className="biller-not-found" variant="danger"><FontAwesomeIcon icon={ faExclamationTriangle } />&nbsp;Utility Not Found</Badge></span>
	}

	return (
		<tr>
			<td><span className="table-biller-name">{ title }</span></td>
			<td>
				<SignupBillerFormField selectedSignup={ selectedSignup } id={ props.id } companyInfo={ companyInfo } fieldId={ BILLERINFOFIELDS.BILLERID } value={ billerId }/>
			</td>
			<td>
				<SignupBillerFormField selectedSignup={ selectedSignup } id={ props.id } companyInfo={ companyInfo } fieldId={ BILLERINFOFIELDS.ACCOUNTNUMBER } value={ acctNum }/>
			</td>
			<td>
				<SignupBillerFormField selectedSignup={ selectedSignup } id={ props.id } companyInfo={ companyInfo } fieldId={ BILLERINFOFIELDS.NOTE } value={ note }/>
			</td>
			<td style={{textAlign: "center"}}>
				<SignupBillerFormField selectedSignup={ selectedSignup } id={ props.id } companyInfo={ companyInfo } fieldId={ BILLERINFOFIELDS.COMPLETE } value={ complete }/>
			</td>
		</tr>
	);
}

function SignupSingle(props) 
{
	const dispatch = useDispatch();
	const history = useHistory();

	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

	let signupCount = useSelector(state => selectSignupCount(state));
	let selectedSignup = useSelector(selectSelectedSignup);
	let operator = useSelector(state => selectOperator(state));

	let allSignupData = useSelector(state => selectAllSignupData(state));
	let editId = useSelector(state => selectEditId(state));
	let editMode = useSelector(state => selectEditMode(state));

	let userInfo = useSelector(state => selectUserInfo(state));

	let billerRows = [];
	for(let i=0;i<MAX_BILLER_ROWS;i++)
	{
		billerRows.push({id: i});
	}

	const actionAddSignup = () => {
		dispatch(addSignup({ operator: operator })); 
		dispatch(setSelectedSignup({value: signupCount}));
		skipToFirstFocus();
	}

	const actionDeleteSignup = () => {
		dispatch(removeSignup({ signup: selectedSignup, operator: operator })); 
		if (selectedSignup > 0 && selectedSignup >= (signupCount-1))
			dispatch(setSelectedSignup({value: (signupCount-2)}));
	}

	useHotkeys('enter', () => actionAddSignup(), {enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT']})

	return (
		<div>
			<Modal show={ showDeleteConfirm }>
				<Modal.Header closeButton>
					<Modal.Title>Delete Current Signup?</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<p>Do you want to delete the current signup?</p>
				</Modal.Body>

				<Modal.Footer>
					<Button onClick={ () => {
						setShowDeleteConfirm(false);
					}
					} variant="secondary">No</Button>
					<Button onClick={ () => {
						actionDeleteSignup();
						setShowDeleteConfirm(false);
					}
					} variant="primary">Yes</Button>
				</Modal.Footer>
			</Modal>
			{
				signupCount <= 0 ?
				<ZeroSignups />
				:
				<Row>
					<Col xs="4" className="signup-details-col">
						<div className="signup-details-div">

							<InputText fieldId={ SIGNUPFIELDS.FORMSEQNUMBER } optional="false" tabIndex="0" maxLength="100" fieldLabel="Form ID" />
							<InputText fieldId={ SIGNUPFIELDS.FORMSOURCE } optional="false" tabIndex="0" maxLength="100" fieldLabel="Source" />
							<InputText fieldId={ SIGNUPFIELDS.CUSTOMERDATE } optional="false" tabIndex="0" maxLength="100" fieldLabel="Date" />
							<InputCheckbox fieldId={ SIGNUPFIELDS.COMPLETE } optional="false" tabIndex="-1" fieldLabel="Complete Form" />
							<hr/>
							<InputText fieldId={ SIGNUPFIELDS.FIRSTNAME } optional="false" tabIndex="0" maxLength="100" fieldLabel="First Name" />
							<InputText fieldId={ SIGNUPFIELDS.LASTNAME } optional="false" tabIndex="0" maxLength="100" fieldLabel="Last Name" />
							<InputText fieldId={ SIGNUPFIELDS.ADDRESS } optional="false" tabIndex="0" maxLength="100" fieldLabel="Address" />
							<InputText fieldId={ SIGNUPFIELDS.ZIP } optional="false" tabIndex="0" maxLength="10" fieldLabel="Zip" />
							<InputText fieldId={ SIGNUPFIELDS.CITY } optional="false" tabIndex="0" maxLength="100" fieldLabel="City" />
							<InputText fieldId={ SIGNUPFIELDS.STATE } optional="false" tabIndex="0" maxLength="2" fieldLabel="State" />
							<InputText fieldId={ SIGNUPFIELDS.PHONENUMBER } optional="true" tabIndex="0" maxLength="100" fieldLabel="Phone" />
							<InputText fieldId={ SIGNUPFIELDS.EMAILADDRESS } optional="true" tabIndex="0" maxLength="100" fieldLabel="EMail" />
							<InputText fieldId={ SIGNUPFIELDS.NOTE } optional="false" tabIndex="-1" maxLength="100" fieldLabel="Header Note" />
							<hr/>
							<InputText fieldId={ SIGNUPFIELDS.FIROUTINGNUMBER } tabIndex="0" optional="false" maxLength="100" fieldLabel="Bank R/T" />
							<InputText fieldId={ SIGNUPFIELDS.FIACCOUNTNUMBER } tabIndex="0" optional="false" maxLength="100" fieldLabel="Bank Acct #" />
							<InputText fieldId={ SIGNUPFIELDS.FIACCONTTYPECHECKING } tabIndex="0" optional="false" fieldLabel="Bank Acct Type" />
							{
								editMode === EDITMODES.EXCEPTIONS &&
								<>
									<hr/>
									<InputText fieldId={ SIGNUPFIELDS.MERGE } tabIndex="0" optional="false" maxLength="100" fieldLabel="Merge Record" />
									<InputText fieldId={ SIGNUPFIELDS.ERRORS } tabIndex="0" optional="false" maxLength="100" fieldLabel="Errors" />
								</>
							}
						</div>
					</Col>
					<Col xs="8" className="biller-table-col">
						<div className="biller-table-div">
							<Table size="sm" borderless className="biller-table">
								<thead>
									<tr>
										<th className="table-single-biller-name">Biller Name</th>
										<th className="table-single-biller-id">Biller ID</th>
										<th className="table-single-biller-account-number">Biller Acct #</th>
										<th className="table-single-biller-note">Detail Note</th>
										<th className="table-single-biller-complete">C</th>
									</tr>
								</thead>
								<tbody>
									{ billerRows.map((value, id) => {
										return <BillerRow key={ "BillerRow"+id } id={ id } />
									})
									}
								</tbody>
							</Table>
						</div>
					</Col>
				</Row>
			}
			<div className="section-spacer"></div>
			<div className="signup-controls">
				<Button size="sm" onClick={ () => { 
					actionAddSignup()
				} }>Add Signup (enter)</Button>
				{
					userInfo.username === 'cgood' &&
					<>
						<span className="signup-control-spacer" />
						<Button size="sm" onClick={ () => { 

							var count = prompt("How many fake signups?", 5);

							if (count > 0)
							{
								let dateStr = dateFormat(new Date(), 'm/d/yyyy');

								for(let f=0;f<count;f++)
								{
									let newSignup = getInitialStateSignup(operator);

									newSignup.formInfo.formSeqNumber = 10000 + signupCount + f;
									
									newSignup.formInfo.merge = true;
									newSignup.formInfo.customerDate = dateStr;
									newSignup.formInfo.firstName = faker.name.firstName();
									newSignup.formInfo.lastName = faker.name.lastName();
									newSignup.formInfo.address = faker.address.streetAddress();
		
									let zipcodeObj = getRandomZipCode();
									newSignup.formInfo.city = zipcodeObj.city;
									newSignup.formInfo.state = zipcodeObj.state;
									newSignup.formInfo.zip = zipcodeObj.zipcode;
		
									let rtObj = getRandomRT();
									newSignup.formInfo.fiRoutingNumber = rtObj.rt;
		
									if (Math.random() < 0.7)
									{
										newSignup.formInfo.phoneNumber = faker.phone.phoneNumber();
									}
		
									if (Math.random() < 0.7)
									{
										newSignup.formInfo.emailAddress = faker.internet.email();
									}
		
									newSignup.formInfo.fiAccontTypeChecking = (Math.random() < 0.7);
									newSignup.formInfo.fiAccountNumber = faker.finance.account();
		
									let billerCount = 1;
									let billerRand = Math.random();
									if (billerRand < 0.3)
										billerCount += 1;
									else if (billerRand < 0.7)
										billerCount += 2;
		
									for(let b=0;b<billerCount;b++)
									{
										let companyObj = getRandomCompany();
		
										newSignup.formInfo.billerInfo[b] = {
											complete:true,
											billerId: companyObj.companyNum,
											accountNumber: faker.finance.account()
										};
									}
		
									dispatch(addSignup({ operator: operator, signup: newSignup })); 
									dispatch(setSelectedSignup({value: signupCount + f}));
								}

								if (allSignupData.signups.length > 0 && allSignupData.signups[0].formInfo.formSeqNumber === undefined)
								{
									dispatch(removeSignup({ signup: 0, operator: operator })); 
									dispatch(setSelectedSignup({value: 0}));
								}
							}
							
						} }>Generate Fake Data&#8230;</Button>
					</>
				}
				<span className="signup-control-spacer" />
				<Button size="sm" disabled={ signupCount <= 0 } onClick={ () => { 
					setShowDeleteConfirm(true);
				} }>Delete Signup&#8230;</Button>
				<span className="signup-control-spacer" />
				<SignupNavigator />
			</div>
		</div>
	);
}

export default SignupSingle;
