export function skipToNextFocus(e)
{
	var tabbables = document.querySelectorAll('button, [href], input, select, textarea');
	let nextItem = false;
	for(let t=0;t<tabbables.length;t++)
	{
		let tabable = tabbables[t];
		if (nextItem && Number(tabable.tabIndex) !== -1)
		{
			tabable.focus();
			break;
		}
		if (tabable === e.target)
		{
			nextItem = true;
		}
	}
}

export function skipToFirstFocus()
{
    var tabbables = document.querySelectorAll('input');
	if (tabbables.length > 0)
	{
		tabbables[0].focus();
	}
}
