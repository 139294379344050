
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Modal, Alert } from 'react-bootstrap';

import ChangePassword from '../controls/ChangePassword';

import {
	isChangePasswordShow,
	setChangePasswordShow,
} from '../store/changePasswordSlice';

import {
	selectUserInfo,
	selectUserCredentials,
} from '../store/userSlice';

import { callServer } from '../utils/APIUtils';

function ChangePasswordModal({ badWords, onChange })
{
	const [userPasswordNew, setUserPasswordNew] = useState('');
	const [userPasswordNewValid, setUserPasswordNewValid] = useState(false);
	const [dangerAlert, setDangerAlert] = useState('');
	const [successAlert, setSuccessAlert] = useState('');

	const dispatch = useDispatch();

	let show = useSelector(isChangePasswordShow);
	let userInfo = useSelector(selectUserInfo);
	let credentials = useSelector(selectUserCredentials);

	const handleShow = () => {
		setDangerAlert('');
		setSuccessAlert('');
	}

	const handleClose = () => {
		dispatch(setChangePasswordShow({ show: false }));
	}
	
	const handleChange = async () => {
		
		let command = { command: 'user-changepassword', newPassword: userPasswordNew };

		setDangerAlert('');

		let signinResponse = await callServer(command, credentials);
		if (signinResponse)
		{			
			if (signinResponse.status >= 200 && signinResponse.status < 300)
			{
				setSuccessAlert("Your password had been updated.");
			}
			else if (signinResponse.status == 419)
			{
				setDangerAlert("Your new password does not conform to the password rules.");
			}
			else if (signinResponse.status == 420)
			{
				setDangerAlert("Your new password has been recently used.");
			}
			else
			{
				setDangerAlert("Error updating password, try again later.");
			}
		}
	};

	return (
		<Modal show={ show } onShow={ handleShow } onHide={ handleClose } size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Change Password</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ 
					dangerAlert &&
						<Alert variant="danger">{ dangerAlert }</Alert>
				}
				{
					successAlert ?
						<Alert variant="success">{ successAlert }</Alert>
						:
						<>
						<ChangePassword badWords={ [ userInfo.username ] } onChange={ (password, valid) => {
							setUserPasswordNew(password);
							setUserPasswordNewValid(valid);
						} }/>
						</>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={ handleClose }>
					Close
				</Button>
				{
					!successAlert &&
					<Button disabled={ !userPasswordNewValid } variant="primary" onClick={ handleChange }>
						Change Password
					</Button>
				}
			</Modal.Footer>
	  </Modal>
	);
}

export default ChangePasswordModal;