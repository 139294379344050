import React, { useState, useRef, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faCheckCircle, faCircle } from '@fortawesome/fontawesome-free-solid'

function FieldValid(props)
{
	return (
		<span className={ "field-valid-icon"+(props.fieldValid === 0?" field-valid-icon-notsure":(props.fieldValid > 0?" field-valid-icon-good":" field-valid-icon-bad")) }>
			&nbsp;<FontAwesomeIcon icon={ (props.fieldValid === 0?faCircle:(props.fieldValid>0?faCheckCircle:faExclamationTriangle)) } />
		</span>
	);
}

export default FieldValid;
