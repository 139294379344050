import { configureStore } from '@reduxjs/toolkit';
import signupsSlice from './signupsSlice';
import selectedSignupSlice from './selectedSignupSlice';
import userSlice from './userSlice';
import editIdSlice from './editIdSlice';
import changePasswordSlice from './changePasswordSlice';

export default configureStore({
	reducer: {
		signups: signupsSlice,
		selectedSignup: selectedSignupSlice,
		user: userSlice,
		editId: editIdSlice,
		changePassword: changePasswordSlice,
	},
});
