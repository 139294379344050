import { createSlice } from '@reduxjs/toolkit';

export const changePasswordSlice = createSlice({
	name: 'changePassword',
	initialState: {
        show: false,
    },
	reducers: {
		setChangePasswordShow: (state, action) => {
			state.show = action.payload.show;
		},
	},
});

export const isChangePasswordShow = (state) => {
	return state.changePassword.show;
}

export const { 
	setChangePasswordShow,
} = changePasswordSlice.actions;

export default changePasswordSlice.reducer;
