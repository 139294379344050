import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './App.css';
import { Button, Row, Col, Form, Table, Navbar, Nav, NavDropdown, NavItem, Spinner, Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/fontawesome-free-solid'

import { callServer, hashPassword } from './utils/APIUtils';

import SignupSaver from './controls/SignupSaver';
import ChangePasswordModal from './modals/ChangePasswordModal';

import {
	setEditId,
	setEditMode,
	selectEditId,
	selectEditMode,
	EDITMODES,
} from './store/editIdSlice';

import {
	isUserSignIn,
	selectUserInfo,
	signOut,
	selectOperator,
	selectUserCredentials
} from './store/userSlice';

import {
	setField,
	setBillerInfo,
	reset,
	addSignup,
	removeSignup,
	selectField,
	selectBillerInfo,
	selectSignupCount,
	selectAllSignupData,
	SIGNUPFIELDS,
	BILLERINFOFIELDS,
	setAllSignupData,
	resetSignups
} from './store/signupsSlice';

import {
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup,
	selectSelectedSignup,
} from './store/selectedSignupSlice';

import SignupSingle from './views/SignupSingle';
import SignupHeaders from './views/SignupHeaders';
import SignupBanking from './views/SignupBanking';
import SignupDetails from './views/SignupDetails';
import SignIn from './views/SignIn';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink,
	useParams,
} from "react-router-dom";

import {
	isChangePasswordShow,
	setChangePasswordShow,
} from './store/changePasswordSlice';

const dateFormat = require("dateformat");
  
function App() 
{
	const [createNewSignupSet, setCreateNewSignupSet] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [dataEntryLogModal, setDataEntryLogModal] = useState({ showing: false });

	let signedIn = useSelector(isUserSignIn);
	let userUserInfo = useSelector(selectUserInfo);
	let operator = useSelector(selectOperator);
	let editId = useSelector(state => selectEditId(state));
	let editMode = useSelector(state => selectEditMode(state));
	let credentials = useSelector(state => selectUserCredentials(state));

	let userTitle = userUserInfo?<span><FontAwesomeIcon icon={ faUser } />&nbsp;&nbsp;{ userUserInfo.username + " ("+operator+")" }&nbsp;</span>:<span></span>;

	const dispatch = useDispatch();

	const getDataEntryLog = async function()
	{
		let dataEntryInfoCommand = { command: 'dataentrylog-get', editMode: editMode, days: 90 };
		let dataEntryInfoResponse = await callServer(dataEntryInfoCommand, credentials);
		if (dataEntryInfoResponse && dataEntryInfoResponse.status === 200 && dataEntryInfoResponse.table)
		{
			let fieldMap = dataEntryInfoResponse.table.fieldMap;

			let modalInfo = {
				showing: true,
				title: "Data Entry Log",
				body: (dataEntryInfoResponse.table.data && dataEntryInfoResponse.table.data.length > 0)?<div>
					<Table striped bordered>
						<thead>
							<tr>
								<th>Date</th>
								<th>Starting ID</th>
								<th>Ending ID</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
						{
							dataEntryInfoResponse.table.data.map((row, index) => {
								return <tr key={ index }>
									<td>{ dateFormat(row[fieldMap["LogDate"]], 'm/d/yyyy hh:MM TT') }</td>
									<td>{ row[fieldMap["FormSeqStart"]] }</td>
									<td>{ row[fieldMap["FormSeqEnd"]] }</td>
									<td>{ row[fieldMap["Total"]] }</td>
								</tr>
							})
						}
						</tbody>
					</Table>
					<small><i>* Last { dataEntryInfoCommand.days } days</i></small>
				</div>:
				<div>No data entry log data for user.</div>
			};

			setDataEntryLogModal(modalInfo);
		}
	}

	const createSignupSetRemote = async function()
	{
		let dataEntryInfoCommand = { command: 'signupset-create', editMode: editMode };
		let dataEntryInfoResponse = await callServer(dataEntryInfoCommand, credentials);

		if (dataEntryInfoResponse)
		{
			if ((dataEntryInfoResponse.status >= 400 && dataEntryInfoResponse.status < 500))
			{
				dispatch(signOut({}));
				dispatch(setEditId({editId:undefined}));
				dispatch(setSelectedSignup({ value: 0 }));
				dispatch(resetSignups({}));
			}
			else
			{
				if (dataEntryInfoResponse.status === 600)
				{
					dataEntryInfoCommand = { command: 'signupset-load', editMode: editMode };
					dataEntryInfoResponse = await callServer(dataEntryInfoCommand, credentials);
				}
				
				if ((dataEntryInfoResponse.status >= 400 && dataEntryInfoResponse.status < 500))
				{
					dispatch(signOut({}));
					dispatch(setEditId({editId:undefined}));
					dispatch(setSelectedSignup({ value: 0 }));
					dispatch(resetSignups({}));
				}
				else
				{
					if (dataEntryInfoResponse.status == 200)
					{
						dispatch(setSelectedSignup({ value: 0 }));
		
						if (dataEntryInfoResponse.signupSetData)
						{
							let addSignupIfEmpty = (editMode === EDITMODES.DATAENTRY);

							dispatch(setAllSignupData({ signupSetData: JSON.parse(dataEntryInfoResponse.signupSetData), operator: operator, addSignupIfEmpty: addSignupIfEmpty }));
						}
		
						if (dataEntryInfoResponse.editId)
						{
							dispatch(setEditId({value: dataEntryInfoResponse.editId}));
						}
					}
				}
			}
		}

		setShowSpinner(false);
	}

	useEffect(() => {

		if (createNewSignupSet)
		{
			setCreateNewSignupSet(false);
			createSignupSetRemote();
		}
		
		return () => {
		}

	}, [createNewSignupSet]);

	return (
		<div className="main-body">
			<ChangePasswordModal />
			<Modal size="lg" show={ dataEntryLogModal.showing } onHide={() => setDataEntryLogModal({ showing: false })}>
				<Modal.Header closeButton={ true }>
					<Modal.Title>{ dataEntryLogModal.title }</Modal.Title>
				</Modal.Header>
				<Modal.Body>{ dataEntryLogModal.body }</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setDataEntryLogModal({ showing: false })}>Close</Button>
				</Modal.Footer>
			</Modal>
			<Router basename={process.env.PUBLIC_URL}>
				<Navbar bg="light" expand="lg">
					<Navbar.Brand><Link to="/" className="brand-router"><img width="32" src={ process.env.PUBLIC_URL + '/StopSign-nofont.svg' }/>&nbsp;&nbsp;Data Entry</Link></Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<div className="navbar-brand-spacer"></div>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto">
							{ signedIn && editId && 
								<>
									<NavItem>
										<NavLink exact to="/" className="nav-link top-nav-tab">
										Single
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink exact to="/signup/headers" className="nav-link top-nav-tab">
										Headers
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink exact to="/signup/details" className="nav-link top-nav-tab">
										Details
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink exact to="/signup/banking" className="nav-link top-nav-tab">
										Banking
										</NavLink>
									</NavItem>
								</>
							}
							{ signedIn && editId && 
								<>
									<div className="navbar-brand-spacer"></div>
									<div className="navbar-save-buttons">
										<SignupSaver />
									</div>
								</>
							}
						</Nav>
						<Nav>
							{
								signedIn && 
								<NavDropdown title={ userTitle } className="navbar-dropdown-hello" id="basic-nav-dropdown" alignRight>
									<NavDropdown.Item onClick={
											(evt) => {
												getDataEntryLog();
											}
										}>Data Entry Log</NavDropdown.Item>
									<NavDropdown.Item onClick={
											(evt) => {
												dispatch(setChangePasswordShow({ show: true }));
											}
										}>Change Password</NavDropdown.Item>
									<NavDropdown.Item onClick={
											async (evt) => {
												await callServer({ command: 'user-signout' }, credentials);
												dispatch(signOut({}));
												dispatch(setEditId({editId:undefined}));
												dispatch(setSelectedSignup({ value: 0 }));
												dispatch(resetSignups({}));
											}
										}>Sign Out</NavDropdown.Item>
								</NavDropdown>
							}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
				<div className="navbar-spacer"></div>
				<div className="body-div">
					{ signedIn ?
						<div>
							{ editId ?
								<Switch>
								<Route exact path="/" component={ SignupSingle } />
								<Route exact path="/index.html" component={ SignupSingle } />
								<Route exact path="/signup/headers" component={ SignupHeaders } />
								<Route exact path="/signup/banking" component={ SignupBanking } />
								<Route exact path="/signup/details" component={ SignupDetails } />
								</Switch>
								:
								<Row className="d-flex justify-content-center create-signupset-button-div">
									<Button onClick={ async () => { 
											setShowSpinner(true);
											setCreateNewSignupSet(true);
										} }>
											<span>Start New Signup Set</span>
											{
												showSpinner && 
												<span>&nbsp;&nbsp;<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp;&nbsp;</span> 
											}
										</Button>
								</Row>
							}
						</div>
						:
						<SignIn />
					}
				</div>
			</Router>
		</div>
	);
}

export default App;
