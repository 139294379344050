export function rtRemoveHyphens(routingNumber)
{
    return routingNumber.trim().replace(/\-/g, "");
}

export function rtAddHyphens(routingNumber)
{
    let ret = routingNumber;

    let temp = routingNumber.trim().replace(/\-/g, "");
    if (temp.length >= 9)
    {
        ret = temp.substr(0, 4) + '-' + temp.substr(4, 4) + '-' + temp.substr(8);
    }

    return ret;
}