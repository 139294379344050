import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	setField,
	setBillerInfo,
	reset,
	addSignup,
	removeSignup,
	selectField,
	selectBillerInfo,
	selectSignupCount,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupsSlice';

import {
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup,
	selectSelectedSignup,
} from '../store/selectedSignupSlice';

import { Form, Button, InputGroup } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faBackward, faForward, faFastBackward, faFastForward } from '@fortawesome/fontawesome-free-solid'

function SignupNavigator(props) 
{
	const dispatch = useDispatch();

	const selected = useSelector(selectSelectedSignup);
	const total = useSelector(selectSignupCount);

	let selectorStr = total<=0?"zero records":(selected+1)+" of "+total;

	return (
		<span className="signup-navigator">
			<Button size="sm" disabled={ total <= 0 || selected<=0 } onClick={ () => { dispatch(setSelectedSignup({ value: 0 })) } }><FontAwesomeIcon icon={ faFastBackward } /></Button>
			<span className="signup-navigator-spacer" />
			<Button size="sm" disabled={ total <= 0 || selected<=0 } onClick={ () => { dispatch(decSelectedSignup()) } }><FontAwesomeIcon icon={ faBackward } /></Button>
			<span className="signup-navigator-spacer-sm" />
			<span className="signup-navigator-text">{ selectorStr }</span>
			<span className="signup-navigator-spacer-sm" />
			<Button size="sm" disabled={ total <= 0 || selected >= (total-1) } onClick={ () => { dispatch(incSelectedSignup()) } }><FontAwesomeIcon icon={ faForward } /></Button>
			<span className="signup-navigator-spacer" />
			<Button size="sm" disabled={ total <= 0 || selected >= (total-1) } onClick={ () => { dispatch(setSelectedSignup({ value: total-1 })) } }><FontAwesomeIcon icon={ faFastForward } /></Button>
		</span>
	);
}

export default SignupNavigator;
