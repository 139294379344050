import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SignupFormField from '../controls/SignupFormField';
import ZeroSignups from '../controls/ZeroSignups';

import { Button, Row, Col, Form, Table, OverlayTrigger, Tooltip, InputGroup, DropdownButton, Dropdown, Popover, Badge, Modal } from 'react-bootstrap';

import {
	selectSignups,
	SIGNUPFIELDS,
	BILLERINFOFIELDS,
	selectSignupCount
} from '../store/signupsSlice';

import {
	isUserSignIn,
	selectUserInfo,
	signOut
} from '../store/userSlice';

import {
	setEditId,
	selectEditId,
	selectEditMode,
	EDITMODES,
} from '../store/editIdSlice';

import {
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup,
	selectSelectedSignup,
} from '../store/selectedSignupSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/fontawesome-free-solid'

function getField(signup, field)
{
	if (signup.formInfo.hasOwnProperty(field))
	{
		return signup.formInfo[field];
	}

	return '';
}

function SignupHeader(props) 
{
	const history = useHistory();
	const dispatch = useDispatch();

	let editMode = useSelector(state => selectEditMode(state));

	let signup = props.signup;
	let index = props.index;

	let formSeqNumber = getField(signup, SIGNUPFIELDS.FORMSEQNUMBER);

	let firstName = getField(signup, SIGNUPFIELDS.FIRSTNAME);
	let lastName = getField(signup, SIGNUPFIELDS.LASTNAME);

	const doEdit = () => {
		dispatch(setSelectedSignup({ value: index }));
		history.push('/');
	}

	let nameField = lastName?<span>{ firstName }&nbsp;{ lastName }</span>:<span>{ firstName }</span>;
	if (editMode === EDITMODES.EXCEPTIONS)
	{
		nameField = <div>
			<div style={{ paddingBottom: "4px" }}>
				<SignupFormField fieldId={ SIGNUPFIELDS.MERGE } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } fieldLabel="Merge Record" />
			</div>
			<SignupFormField fieldId={ SIGNUPFIELDS.ERRORS } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
		</div>
			
	}

	return (
		<tr key={ "signup-headers-tr-"+index }>
			<td key={ "signup-headers-td-edit-"+index } className="table-headers-body-edit">
				<a href="#" onClick={ () => { doEdit(); } }><FontAwesomeIcon icon={ faEdit } /></a>
			</td>
			<td key={ "signup-headers-td-form-id-"+index } className="table-headers-body-form-id">
				<SignupFormField fieldId={ SIGNUPFIELDS.FORMSEQNUMBER } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
			</td>
			<td key={ "signup-headers-td-form-source-"+index } className="table-headers-body-form-source">
				<SignupFormField fieldId={ SIGNUPFIELDS.FORMSOURCE } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
			</td>
			<td key={ "signup-headers-td-form-operator-"+index } className="table-headers-body-form-operator">
				{ getField(signup, SIGNUPFIELDS.OPERATOR) }
			</td>
			<td key={ "signup-headers-td-body-name-"+index } className="table-headers-body-name">
				{ nameField }
			</td>
			<td key={ "signup-headers-td-routing-number-"+index } className="table-headers-body-routing-number">
				<SignupFormField fieldId={ SIGNUPFIELDS.FIROUTINGNUMBER } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
			</td>
			<td key={ "signup-headers-td-acct-number-"+index } className="table-headers-body-acct-number">
				<SignupFormField fieldId={ SIGNUPFIELDS.FIACCOUNTNUMBER } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
			</td>
			<td key={ "signup-headers-td-acct-type-"+index } className="table-headers-body-acct-type">
				<SignupFormField fieldId={ SIGNUPFIELDS.FIACCONTTYPECHECKING } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
			</td>
			<td key={ "signup-headers-td-note-"+index } className="table-headers-body-note">
				<SignupFormField fieldId={ SIGNUPFIELDS.NOTE } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
			</td>
			<td key={ "signup-headers-td-complete-"+index } className="table-headers-body-complete">
				<SignupFormField fieldId={ SIGNUPFIELDS.COMPLETE } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
			</td>
		</tr>
	);
}

function SignupHeaders(props) 
{
	let userInfo = useSelector(selectUserInfo);

	let signups = useSelector(state => selectSignups(state));
	let signupCount = useSelector(state => selectSignupCount(state));
	let editMode = useSelector(state => selectEditMode(state));

	return (
		<div>
			{ signupCount <= 0 ?
				<ZeroSignups />
				:
				<Table size="sm" className="biller-table">
					<thead>
						<tr>
							<th className="table-headers-header-edit">Edit</th>
							<th className="table-headers-header-form-id">Form ID</th>
							<th className="table-headers-header-form-source">Source</th>
							<th className="table-headers-header-form-operator">Operator</th>
							<th className="table-headers-header-name">{ editMode === EDITMODES.EXCEPTIONS?"Errors":"Name" }</th>
							<th className="table-headers-header-routing-number">Bank R/T</th>
							<th className="table-headers-header-acct-number">Bank Acct #</th>
							<th className="table-headers-header-acct-type">Bank Acct Type</th>
							<th className="table-headers-header-note">Header Note</th>
							<th className="table-headers-header-complete">Complete</th>
						</tr>
					</thead>
					<tbody>
						{
							signups.map((signup, index) => {
								return <SignupHeader userInfo={ userInfo } signup={ signup } index={ index } />
							})
						}
					</tbody>
				</Table>
			}
		</div>
	);
}

export default SignupHeaders;
