import { createSlice } from '@reduxjs/toolkit';

import { hashPassword } from '../utils/APIUtils';

import { DEFAULT_OPERATOR } from './signupsSlice';

export const userSlice = createSlice({
	name: 'user',
	initialState: {
        username: undefined,
        jwt: undefined,
        userInfo: {}
    },
	reducers: {
		setUser: (state, action) => {
			state.username = action.payload.username;
			state.jwt = action.payload.jwt;
			if (action.payload.hasOwnProperty("userInfo"))
			{
				state.userInfo = action.payload.userInfo;
			}
		},
		setUserInfo: (state, action) => {
			state.userInfo = action.payload.userInfo;
		},
		signOut: (state, action) => {
			state.username = undefined;
			state.jwt = undefined;
			state.userInfo = {}
		},
	},
});

export const selectUserInfo = (state) => {
	return state.user.userInfo;
}

export const selectUserCredentials = (state) => {

	let credentials = undefined;
	
	if (state.user.jwt)
	{
		credentials = {jwt: state.user.jwt};
	}

	return credentials;
}

export const isUserSignIn = (state) => {
	return state.user.username !== undefined;
}

export const selectOperator = (state) => {

	if (state.user.userInfo && state.user.userInfo.companyNums && state.user.userInfo.companyNums.length > 0)
	{
		return state.user.userInfo.companyNums[0];
	}

	return DEFAULT_OPERATOR;
}

export const { 
	setUser,
	setUserInfo,
	signOut,
} = userSlice.actions;

export default userSlice.reducer;
