import React, { useState, useRef, useEffect } from 'react';

import { Alert, Row, Col } from 'react-bootstrap';

function ZeroSignups(props)
{
	return (
		<div className="zero-signups-div">
			<Alert className="zero-signups-alert" variant="warning">No Signups</Alert>
		</div>
	);
}

export default ZeroSignups;