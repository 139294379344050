import { createSlice } from '@reduxjs/toolkit';

export const EDITMODES = {
	DATAENTRY: "dataentry",
	EXCEPTIONS: "exceptions",
};

export const editIdSlice = createSlice({
	name: 'editId',
	initialState: {
        editId: undefined,
		editMode: EDITMODES.DATAENTRY,
    },
	reducers: {
		setEditId: (state, action) => {
			state.editId = action.payload.value;
		},
		setEditMode: (state, action) => {
			state.editMode = action.payload.value;
		},
	},
});

export const selectEditId = (state) => {
	return state.editId.editId;
}

export const selectEditMode = (state) => {
	return state.editId.editMode;
}

export const { 
	setEditId,
	setEditMode,
} = editIdSlice.actions;

export default editIdSlice.reducer;
