import React, { useState, useCallback, useMemo } from 'react';
import DataGrid, { TextEditor } from 'react-data-grid';

import { Form, Row, Col, InputGroup, Button } from 'react-bootstrap';

import {
	getFINames
} from '../utils/DataEntryInfoUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/fontawesome-free-solid'

function createColumns(columnKey = '')
{
	return [
		{ key: 'inputRT', name: (columnKey=="inputRT"?"* ":"")+'Input R/T', resizable: true, sortable: true, width: 120, editor: TextEditor },
		{ key: 'useRT', name: (columnKey=="useRT"?"* ":"")+'Use R/T', sortable: true, width: 110, editor: TextEditor },
		{ key: 'name', name: (columnKey=="name"?"* ":"")+'Name', resizable: true, sortable: true },
		{ key: 'city', name: (columnKey=="city"?"* ":"")+'City', resizable: true, width: 140, sortable: true },
		{ key: 'state', name: (columnKey=="state"?"* ":"")+'State', sortable: true, width: 35 },
	];
}
  
function FINameGrid(props)
{
	const [rows] = useState(getFINames());
	const [columns, setColumns] = useState(createColumns('inputRT'));
	const [[sortColumn, sortDirection], setSort] = useState(['inputRT', 'ASC']);
	const [filter, setFilter] = useState('');
	const [filterField, setFilterField] = useState('inputRT');

	const handleSort = useCallback((columnKey, direction) => {

		setColumns(createColumns(columnKey));

		setSort([columnKey, direction]);
	  }, []);

	const sortedRows = useMemo((Row) => {

		if (sortDirection === 'NONE')
		{
			return rows;
		}

		let sortedRows = [...rows];

		if (filter)
		{
			let filterLower = filter.trim().toUpperCase();

			let isStarts = true;
			if (filterLower.startsWith("*"))
			{
				isStarts = false;
				filterLower = filterLower.substr(1);
			}

			let newRows = [];
			for(let i=0;i<sortedRows.length;i++)
			{
				if ((isStarts && sortedRows[i][filterField].toUpperCase().startsWith(filterLower)) || (!isStarts && sortedRows[i][filterField].toUpperCase().endsWith(filterLower)))
				{
					newRows.push(sortedRows[i]);
				}
			}

			sortedRows = newRows;
		}

		if (sortColumn)
		{
			sortedRows = sortedRows.sort((a, b) => a[sortColumn].localeCompare(b[sortColumn]));
		}

		return sortDirection === 'DESC' ? sortedRows.reverse() : sortedRows;

	}, [rows, filter, filterField, sortDirection, sortColumn]);

	return (
		<div className="signup-banking-finames-div">
			<Row>
				<Col xs="4">
				</Col>
				<Col xs="8">
				<InputGroup className="mb-3">
						<InputGroup.Prepend>
						<InputGroup.Text id="basic-addon1">Filter</InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control 
							className="signup-banking-finames-filter-text shadow-none"
							as="input" 
							type="text"
							value={ filter }
							onChange={ e => { setFilter(e.target.value); } }
							/>
						<InputGroup.Append>
							<Button className="signup-banking-finames-clear-filter shadow-none bg-transparent" variant="outline-secondary" onClick={ e => { setFilter(''); } }>
								<FontAwesomeIcon icon={ faTimes } />
							</Button>
						</InputGroup.Append>
						<InputGroup.Append>
							<Form.Control 
								className="signup-banking-finames-select-filter shadow-none" 
								as="select" 
								value={ filterField }
								onChange={e => { setFilterField(e.target.value); } }
								>
									<option key={ "inputRT" } value={ "inputRT" }>Input RT</option>
									<option key={ "useRT" } value={ "useRT" }>Use RT</option>
									<option key={ "name" } value={ "name" }>Name</option>
									<option key={ "city" } value={ "city" }>City</option>
									<option key={ "state" } value={ "state" }>State</option>
							</Form.Control>
						</InputGroup.Append>
					</InputGroup>
				</Col>
			</Row>

		<DataGrid style={{ height: "calc(100vh - 170px)" }} 
			columns={ columns } 
			rows={ sortedRows }
			sortDirection={ sortDirection }
			onSort={ handleSort }
		/>
	</div>
);
}

export default FINameGrid;
