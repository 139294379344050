import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SignupFormField from '../controls/SignupFormField';
import SignupBillerFormField from '../controls/SignupBillerFormField';
import ZeroSignups from '../controls/ZeroSignups';

import { Button, Row, Col, Form, Table, OverlayTrigger, Tooltip, InputGroup, DropdownButton, Dropdown, Popover, Badge, Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faExclamationTriangle } from '@fortawesome/fontawesome-free-solid'

import {
	isUserSignIn,
	selectUserInfo,
	signOut
} from '../store/userSlice';

import {
	selectSignups,
	setField,
	setBillerInfo,
	reset,
	addSignup,
	removeSignup,
	selectField,
	selectBillerInfo,
	selectSignupCount,
	SIGNUPFIELDS,
	BILLERINFOFIELDS,
} from '../store/signupsSlice';

import {
	lookupZipcode,
	lookupFINames,
	getFormSource,
	getHeaderNotes,
	getDetailNotes,
	getCompanyInfo,
	isCompanyAcctNumValid,
} from '../utils/DataEntryInfoUtils';

import {
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup,
	selectSelectedSignup,
} from '../store/selectedSignupSlice';

import {
	setEditId,
	selectEditId,
	selectEditMode,
	EDITMODES,
} from '../store/editIdSlice';

function getField(signup, field)
{
	if (signup.formInfo.hasOwnProperty(field))
	{
		return signup.formInfo[field];
	}

	return '';
}

function SignupDetail(props)
{
	const history = useHistory();
	const dispatch = useDispatch();

	let editMode = useSelector(state => selectEditMode(state));

	let detail = props.detail;

	let signup = detail.signup;
	let index = detail.index;

	let formSeqNumber = getField(signup, SIGNUPFIELDS.FORMSEQNUMBER);

	let billerId = useSelector(state => selectBillerInfo(state, index, detail.detailKey, BILLERINFOFIELDS.BILLERID)) || '';
	let acctNum = useSelector(state => selectBillerInfo(state, index, detail.detailKey, BILLERINFOFIELDS.ACCOUNTNUMBER)) || '';
	let note = useSelector(state => selectBillerInfo(state, index, detail.detailKey, BILLERINFOFIELDS.NOTE)) || '';
	let complete = useSelector(state => selectBillerInfo(state, index, detail.detailKey, BILLERINFOFIELDS.COMPLETE));

	let companyInfo = getCompanyInfo(billerId);

	let title = <span>&nbsp;</span>;
	if (companyInfo)
	{
		title = <span>{ companyInfo.name }</span>;
	}
	else if (billerId.length > 0)
	{
		title = <span><Badge className="biller-not-found" variant="danger"><FontAwesomeIcon icon={ faExclamationTriangle } />&nbsp;Utility Not Found</Badge></span>
	}

	const doEdit = () => {
		dispatch(setSelectedSignup({ value: index }));
		history.push('/');
	}

	let detailCount = (detail.detailCount>0?detail.detailCount:1);

	return (
		<tr key={ "signup-details-tr-"+index+'-'+detail.detailKey }>
			{ detail.detailIndex == 0 &&
				<td key={ "signup-details-td-edit-"+index+'-'+detail.detailKey } rowSpan={ detailCount } className="table-details-body-edit">
					<a href="#" onClick={ () => { doEdit(); } }><FontAwesomeIcon icon={ faEdit } /></a>
				</td>
			}
			{ detail.detailIndex == 0 &&
				<td key={ "signup-details-td-form-id-"+index+'-'+detail.detailKey } rowSpan={ detailCount } className="table-details-body-form-id">
					{ formSeqNumber }<br/>{ getField(signup, SIGNUPFIELDS.FORMSOURCE) }&nbsp;/&nbsp;{ getField(signup, SIGNUPFIELDS.OPERATOR) }
				</td>
			}
			{
				detail.detailIndex == 0 && editMode === EDITMODES.EXCEPTIONS &&
				<td key={ "signup-details-td-errors-"+index+'-'+detail.detailKey } rowSpan={ detailCount } className="table-details-body-errors">
					<div>
						<div style={{ paddingBottom: "4px" }}>
							<SignupFormField fieldId={ SIGNUPFIELDS.MERGE } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } fieldLabel="Merge Record" />
						</div>
						<SignupFormField fieldId={ SIGNUPFIELDS.ERRORS } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
					</div>
				</td>
			}
			{
				detail.detailCount === 0 ?
				<td colSpan={ 5 } key={ "signup-details-td-biller-missing-"+index+'-'+detail.detailKey } className="table-details-body-biller-missing"><Badge className="biller-not-found" variant="danger"><FontAwesomeIcon icon={ faExclamationTriangle } />&nbsp;Signup is missing utility data</Badge></td>
				:
				<>
					<td key={ "signup-details-td-biller-name-"+index+'-'+detail.detailKey } className="table-details-body-biller-name">
						{ title }	
					</td>
					<td key={ "signup-details-td-biller-id-"+index+'-'+detail.detailKey } className="table-details-body-biller-id">
						<SignupBillerFormField selectedSignup={ index } id={ detail.detailKey } companyInfo={ companyInfo } fieldId={ BILLERINFOFIELDS.BILLERID } value={ billerId }/>
					</td>
					<td key={ "signup-details-td-biller-acct-number-"+index+'-'+detail.detailKey } className="table-details-body-biller-acct-number">
						<SignupBillerFormField selectedSignup={ index } id={ detail.detailKey } companyInfo={ companyInfo } fieldId={ BILLERINFOFIELDS.ACCOUNTNUMBER } value={ acctNum }/>
					</td>
					<td key={ "signup-details-td-biller-note-"+index+'-'+detail.detailKey }  className="table-details-body-biller-note">
						<SignupBillerFormField selectedSignup={ index } id={ detail.detailKey } companyInfo={ companyInfo } fieldId={ BILLERINFOFIELDS.NOTE } value={ note }/>
					</td>
					<td key={ "signup-details-td-biller-complete-"+index+'-'+detail.detailKey } className="table-details-body-biller-complete">
						<SignupBillerFormField selectedSignup={ index } id={ detail.detailKey } companyInfo={ companyInfo } fieldId={ BILLERINFOFIELDS.COMPLETE } value={ complete }/>
					</td>
				</>
			}
		</tr>
	);
}

function SignupDetails(props) 
{
	let userInfo = useSelector(selectUserInfo);

	let editMode = useSelector(state => selectEditMode(state));

	let signups = useSelector(state => selectSignups(state));
	let signupCount = useSelector(state => selectSignupCount(state));

	let details = [];
	for(let index=0;index<signups.length;index++)
	{
		let signup = signups[index];

		let detailsInner = [];
		for(let key in signup.formInfo.billerInfo)
		{
			let billerInfo = signup.formInfo.billerInfo[key];
			if ((billerInfo[BILLERINFOFIELDS.BILLERID] && billerInfo[BILLERINFOFIELDS.BILLERID].length > 0) || 
				(billerInfo[BILLERINFOFIELDS.ACCOUNTNUMBER] && billerInfo[BILLERINFOFIELDS.ACCOUNTNUMBER].length > 0))
			{
				detailsInner.push({key: key, billerInfo: billerInfo});
			}
		}

		if (detailsInner.length > 0)
		{
			for(let d=0;d<detailsInner.length;d++)
			{
				let detail = detailsInner[d];
				details.push({signup: signup, index: index, detail: detail.billerInfo, detailKey: detail.key, detailIndex: d, detailCount: detailsInner.length});
			}
		}
		else
		{
			details.push({signup: signup, index: index, detail: undefined, detailKey: undefined, detailIndex: 0, detailCount: 0});
		}
	}

	return (
		<div>
			{ signupCount <= 0 ?
				<ZeroSignups />
				:
				<Table size="sm" className="biller-table">
					<thead>
						<tr>
							<th className="table-details-header-edit">Edit</th>
							<th className="table-details-header-form-id">Form Info</th>
							{
								editMode === EDITMODES.EXCEPTIONS &&
								<th className="table-details-header-errors">Errors</th>
							}
							<th className="table-details-header-biller-name">Biller Name</th>
							<th className="table-details-header-biller-id">Biller ID</th>
							<th className="table-details-header-biller-acct-number">Biller Acct #</th>
							<th className="table-details-header-biller-note">Detail Note</th>
							<th className="table-details-header-biller-complete">Complete</th>
						</tr>
					</thead>
					<tbody>
						{
							details.map((detail, index) => {
								return <SignupDetail userInfo={ userInfo } detail={ detail } />
							})
						}
					</tbody>
				</Table>
			}
		</div>
	);
}

export default SignupDetails;
