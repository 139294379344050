import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DataGrid from 'react-data-grid';

import SignupFormField from '../controls/SignupFormField';
import ZeroSignups from '../controls/ZeroSignups';
import FINameGrid from '../controls/FINameGrid';

import { Button, Row, Col, Form, Table, OverlayTrigger, Tooltip, InputGroup, DropdownButton, Dropdown, Popover, Badge, Modal } from 'react-bootstrap';

import {
	setEditId,
	setEditMode,
	selectEditId,
	selectEditMode,
	EDITMODES,
} from '../store/editIdSlice';

import {
	selectSignups,
	SIGNUPFIELDS,
	BILLERINFOFIELDS,
	selectSignupCount
} from '../store/signupsSlice';

import {
	isUserSignIn,
	selectUserInfo,
	signOut
} from '../store/userSlice';

import {
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup,
	selectSelectedSignup,
} from '../store/selectedSignupSlice';

import { rtAddHyphens, rtRemoveHyphens } from '../utils/SignupUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faUser, faDatabase, faHashtag } from '@fortawesome/fontawesome-free-solid'

import {
	getFINames,
	lookupFINames,
} from '../utils/DataEntryInfoUtils';

function getField(signup, field)
{
	if (signup.formInfo.hasOwnProperty(field))
	{
		return signup.formInfo[field];
	}

	return '';
}

function SignupHeader(props) 
{
	const history = useHistory();
	const dispatch = useDispatch();

	let editMode = useSelector(state => selectEditMode(state));

	let signup = props.signup;
	let index = props.index;

	let fiName = getField(signup, SIGNUPFIELDS.FINAME);
	let fiCity = getField(signup, SIGNUPFIELDS.FICITY);
	let fiOrig = getField(signup, SIGNUPFIELDS.FIROUTINGNUMBERORIG);

	let rtNumber = getField(signup, SIGNUPFIELDS.FIROUTINGNUMBER);
	let lookup = (rtRemoveHyphens(rtNumber) >= 9)?lookupFINames(rtNumber):undefined;

	let lookupInfo = <span><FontAwesomeIcon icon={ faDatabase } />&nbsp;<i>[routing number not found]</i></span>;
	if (lookup)
	{
		lookupInfo = <span><FontAwesomeIcon icon={ faDatabase } />&nbsp;{ lookup.name }&nbsp;/&nbsp;{ lookup.city }&nbsp;{ lookup.state }</span>;
	}

	let fiOrigSpan = <>{ fiOrig?<><span>{ fiOrig }</span><br/></>:<></> }</>;

	let fiInfo = <span><FontAwesomeIcon icon={ faUser } />&nbsp;{ fiOrigSpan }<i>[no user bank info]</i></span>;

	if (fiName && fiCity)
	{
		fiInfo = <span><FontAwesomeIcon icon={ faUser } />&nbsp;{ fiOrigSpan }{ fiName }&nbsp;/&nbsp;{ fiCity }</span>;
	}
	else if (fiCity)
	{
		fiInfo = <span><FontAwesomeIcon icon={ faUser } />&nbsp;{ fiOrigSpan }<i>[no user bank name]</i>&nbsp;/&nbsp;{ fiCity }</span>;
	}
	else if (fiName)
	{
		fiInfo = <span><FontAwesomeIcon icon={ faUser } />&nbsp;{ fiOrigSpan }{ fiName }&nbsp;/&nbsp;<i>[no user bank city]</i></span>;
	}

	const doEdit = () => {
		dispatch(setSelectedSignup({ value: index }));
		history.push('/');
	}

	return (
		<tr key={ "signup-banking-tr-"+index }>
			<td key={ "signup-banking-td-edit-"+index } className="table-banking-body-edit">
				<a href="#" onClick={ () => { doEdit(); } }><FontAwesomeIcon icon={ faEdit } /></a>
			</td>
			<td key={ "signup-banking-td-form-id-"+index } className="table-banking-body-form-id">
			{ getField(signup, SIGNUPFIELDS.FORMSEQNUMBER) }<br/>{ getField(signup, SIGNUPFIELDS.FORMSOURCE) }&nbsp;/&nbsp;{ getField(signup, SIGNUPFIELDS.OPERATOR) }
			</td>
			<td key={ "signup-banking-td-complete-"+index } className="table-banking-body-complete">
				<SignupFormField fieldId={ SIGNUPFIELDS.COMPLETE } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
			</td>
			<td key={ "signup-banking-td-note-"+index } className="table-banking-body-note">
				<SignupFormField fieldId={ SIGNUPFIELDS.NOTE } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
			</td>
			<td key={ "signup-banking-td-acct-number-"+index } className="table-banking-body-acct-number">
				<Form.Group>
				<SignupFormField fieldId={ SIGNUPFIELDS.FIACCOUNTNUMBER } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
				<SignupFormField fieldId={ SIGNUPFIELDS.FIACCONTTYPECHECKING } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
				</Form.Group>
			</td>
			<td key={ "signup-banking-td-body-name-"+index } className="table-banking-body-name">
				<SignupFormField fieldId={ SIGNUPFIELDS.FIROUTINGNUMBER } tabIndex={ 0 } maxLength={ 100 } selectedSignup={ index } />
				{ lookupInfo }<br/>
				{ editMode === EDITMODES.EXCEPTIONS && fiInfo }
			</td>
		</tr>
	);
}

function SignupBanking(props) 
{
	let userInfo = useSelector(selectUserInfo);

	let signups = useSelector(state => selectSignups(state));
	let signupCount = useSelector(state => selectSignupCount(state));

	let finames = getFINames();

	return (
		<div>
			<Row>
				<Col xs="7">
				{ signupCount <= 0 ?
					<ZeroSignups />
					:
					<Table size="sm" className="biller-table">
						<thead>
							<tr>
								<th className="table-banking-header-edit">Edit</th>
								<th className="table-banking-header-form-id">Form Info</th>
								<th className="table-banking-header-complete">C</th>
								<th className="table-banking-header-note">Header Note</th>
								<th className="table-banking-header-acct-number">Bank Account</th>
								<th className="table-banking-header-name">Bank R/T Info</th>
							</tr>
						</thead>
						<tbody>
							{
								signups.map((signup, index) => {
									return <SignupHeader userInfo={ userInfo } signup={ signup } index={ index } />
								})
							}
						</tbody>
					</Table>
				}
				</Col>
				<Col xs="5">
					<FINameGrid />
				</Col>
			</Row>
		</div>
	);
}

export default SignupBanking;
