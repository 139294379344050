
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Row, Col, Form, Table, OverlayTrigger, Tooltip, InputGroup, DropdownButton, Dropdown, Popover, Badge, Modal } from 'react-bootstrap';

import FieldValid from './FieldValid';

import { skipToNextFocus, skipToFirstFocus } from '../utils/DOMUtils';
import { rtAddHyphens, rtRemoveHyphens } from '../utils/SignupUtils';

import {
	setField,
	setBillerInfo,
	reset,
	addSignup,
	removeSignup,
	selectField,
	selectBillerInfo,
	selectSignupCount,
	SIGNUPFIELDS,
	BILLERINFOFIELDS
} from '../store/signupsSlice';

import {
	setSelectedSignup,
	incSelectedSignup,
	decSelectedSignup,
	selectSelectedSignup,
} from '../store/selectedSignupSlice';

import {
	lookupZipcode,
	lookupFINames,
	getFormSource,
	getHeaderNotes,
	getDetailNotes,
	getCompanyInfo,
	isCompanyAcctNumValid,
} from '../utils/DataEntryInfoUtils';

function SignupBillerFormField(props)
{
	const dispatch = useDispatch();

	let selectedSignup = props.selectedSignup;
	let companyInfo = props.companyInfo;
	let value = props.value;

	if (props.fieldId === BILLERINFOFIELDS.ACCOUNTNUMBER)
	{
		let acctNumValid = 0;
		if (companyInfo)
		{	
			if (value.length > 0)
			{
				acctNumValid = isCompanyAcctNumValid(companyInfo, value)?1:-1;
			}
		}

		let popOver = <Popover id={ props.fieldId+":"+selectedSignup+":"+props.id+'_popOver' }>
				<Popover.Title as="h3">
					{ companyInfo?companyInfo.name:"Biller Not Found" }
				</Popover.Title>
				<Popover.Content>
					{ companyInfo?companyInfo.acctNumDesc:"Biller "+value+" Not Found" }
				</Popover.Content>
			</Popover>;

		return (
			<InputGroup key={ props.fieldId+":"+selectedSignup+":"+props.id+'_group' } >
				{
					companyInfo ?
					<OverlayTrigger
						placement={ 'bottom' }
						overlay={ popOver }
						key={ props.fieldId+":"+selectedSignup+":"+props.id+'_overlaytrigger' } 
						trigger='focus'
						>
						<Form.Control 
							size="sm" 
							className="table-biller-acctnum" 
							key={ props.fieldId+":"+selectedSignup+":"+props.id+'_pctrl' } 
							value={ value }
							tabIndex="0"
							onChange={e => dispatch(setBillerInfo({ signup: selectedSignup, id: props.id, field: props.fieldId, value: e.target.value })) }
							/>
					</OverlayTrigger>
					:
					<Form.Control 
						size="sm" 
						className="table-biller-acctnum" 
						key={ props.fieldId+":"+selectedSignup+":"+props.id+'_ctrl' } 
						value={ value }
						tabIndex="0"
						onChange={e => dispatch(setBillerInfo({ signup: selectedSignup, id: props.id, field: props.fieldId, value: e.target.value })) }
						/>
				}
				<FieldValid key={ props.fieldId+":"+selectedSignup+":"+props.id+'_valid' } fieldValid={ acctNumValid } />
			</InputGroup>
		);
	}

	if (props.fieldId === BILLERINFOFIELDS.NOTE)
	{
		let notesArr = getDetailNotes();

		return (
			<InputGroup>
				<Form.Control 
						size="sm" 
						className="table-biller-node" 
						id={ props.fieldId+":"+selectedSignup+":"+props.id+'_ctrl' } 
						value={ value }
						tabIndex="-1"
						onChange={e => dispatch(setBillerInfo({ signup: selectedSignup, id: props.id, field: props.fieldId, value: e.target.value })) }
						/>

				<DropdownButton
					as={ InputGroup.Append }
					size="sm" 
					variant="outline-secondary"
					tabIndex="-1"
					title=""
					id={ props.fieldId+":"+selectedSignup+":"+props.id+'_dropdown' } 
					onFocus={ (e) => { skipToNextFocus(e) } }
					>
					{
						notesArr.map((item, index) => {
							return <Dropdown.Item key={ 'biller_note_'+index } eventKey={ item.note } onSelect={
								(e) => {
									dispatch(setBillerInfo({ signup: selectedSignup, id: props.id, field: props.fieldId, value: e }));
								}
							}>{ item.note }</Dropdown.Item>
						})
					}
				</DropdownButton>
			</InputGroup>
		);
	}

	if (props.fieldId === BILLERINFOFIELDS.COMPLETE)
	{
		return (
			<Form.Check 
				className="signup-checkbox" 
				type="checkbox" 
				checked={ value }
				tabIndex="-1"
				id={ props.fieldId+":"+selectedSignup+":"+props.id } 
				onChange={e => dispatch(setBillerInfo({ signup: selectedSignup, id: props.id, field: props.fieldId, value: e.target.checked })) }
				/>
		);
	}

	if (props.fieldId === BILLERINFOFIELDS.BILLERID)
	{
		return (
			<Form.Control 
				size="sm" 
				className="table-biller-id" 
				id={ props.fieldId+":"+selectedSignup+":"+props.id } 
				value={ value }
				tabIndex="0"
				onChange={e => {
					dispatch(setBillerInfo({ signup: selectedSignup, id: props.id, field: props.fieldId, value: e.target.value }));
					let companyInfoLocal = getCompanyInfo(e.target.value);
					let name = companyInfoLocal?companyInfoLocal.name:undefined;
					dispatch(setBillerInfo({ signup: selectedSignup, id: props.id, field: BILLERINFOFIELDS.NAME, value: name }));
				 }
				}
				type="text" 
				/>
		);
	}

	return (
		<Form.Control 
			size="sm" 
			className="table-biller-id" 
			id={ props.fieldId+":"+selectedSignup+":"+props.id } 
			value={ value }
			tabIndex="0"
			onChange={e => dispatch(setBillerInfo({ signup: selectedSignup, id: props.id, field: props.fieldId, value: e.target.value })) }
			type="text" 
			/>
	);
}

export default SignupBillerFormField;
