import { createSlice } from '@reduxjs/toolkit';

var dateFormat = require("dateformat");

export const NOBILLER = "None:0"
export const DEFAULT_FORMSOURCE = "888";
export const DEFAULT_OPERATOR = "JEG";

export const SIGNUPFIELDS = {
	FORMSTATE: 'formState',
	FIRSTNAME: 'firstName',
	LASTNAME: 'lastName',
	ADDRESS: 'address',
	CITY: 'city',
	STATE: 'state',
	ZIP: 'zip',
	PHONENUMBER: 'phoneNumber',
	EMAILADDRESS: 'emailAddress',
	FINAME: 'fiName',
	FICITY: 'fiCity',
	FIACCONTTYPECHECKING: 'fiAccontTypeChecking',
	FIROUTINGNUMBER: 'fiRoutingNumber',
	FIACCOUNTNUMBER: 'fiAccountNumber',
	AUTHORIZATIONCODE: 'authorizationCode',
	FORMSEQNUMBER: 'formSeqNumber',
	CUSTOMERDATE: 'customerDate',
	FORMSOURCE: 'formSource',
	COMPLETE: 'complete',
	OPERATOR: 'operator',
	NOTE: 'note',
	MERGE: 'merge',
	ERRORS: 'errors',

	FIROUTINGNUMBERORIG: 'fiRoutingNumberOrig',
};

export const BILLERINFOFIELDS = {
	BILLERID: 'billerId',
	NAME: 'name',
	ACCOUNTNUMBER: 'accountNumber',
	COMPLETE: 'complete',
	NOTE: 'note'
};

export const getInitialStateSignup = (operator = DEFAULT_OPERATOR) => {
	return {
		formInfo: {
			fiAccontTypeChecking: true,
			complete: true,
			formSource: DEFAULT_FORMSOURCE,
			operator: operator,
			merge: true,
			billerInfo: {}
		}
	};
};

const getInitialState = (operator = DEFAULT_OPERATOR) => {
	return {
		revision: 0,
		signups: [getInitialStateSignup(operator)]
	};
};

export const signupsSlice = createSlice({
	name: 'signups',
	initialState: getInitialState(),
	reducers: {
		setField: (state, action) => {
			if (action.payload.signup < state.signups.length)
			{
				state.signups[action.payload.signup].formInfo[action.payload.field] = action.payload.value;
				state.revision++;
			}
		},
		setBillerInfo: (state, action) => {
			if (action.payload.signup < state.signups.length)
			{
				if (!state.signups[action.payload.signup].formInfo.billerInfo.hasOwnProperty(action.payload.id))
				{
					state.signups[action.payload.signup].formInfo.billerInfo[action.payload.id] = { complete:true };
				}
				state.signups[action.payload.signup].formInfo.billerInfo[action.payload.id][action.payload.field] = action.payload.value;
				state.revision++;
			}
		},
		resetSignups: (state, action) => {
			state.revision = 0;
			state.signups = [getInitialStateSignup(action.payload.operator)]
		},
		addSignup: (state, action) => {

			let newSignup = undefined;

			if (action.payload.hasOwnProperty("signup"))
			{
				newSignup = action.payload.signup;
			}
			else
			{
				newSignup = getInitialStateSignup(action.payload.operator);

				if (state.signups.length > 0)
				{
					let lastSignup = state.signups[state.signups.length-1];
		
					if (lastSignup.formInfo.hasOwnProperty("formSource"))
					{
						let formSource = lastSignup.formInfo["formSource"];
						if (formSource)
						{
							newSignup.formInfo.formSource = formSource;
						}
					}
				}
			}

			state.signups.push(newSignup);
			state.revision++;
		},
		removeSignup: (state, action) => {			
			if (action.payload.signup < state.signups.length)
			{
				state.signups.splice(action.payload.signup, 1);
			}

			state.revision++;
		},
		setAllSignupData: (state, action) => {
			
			state.signups = action.payload.signupSetData.signups;

			if (action.payload.addSignupIfEmpty && state.signups.length <= 0)
			{
				state.signups = [getInitialStateSignup(action.payload.operator)];
			}

			if (action.payload.incRevision)
			{
				state.revision++;
			}
			else
			{
				state.revision = (action.payload.revision?action.payload.revision:0);
			}
		}
	},
});

export const { 
	setField,
	setBillerInfo,
	resetSignups,
	addSignup,
	removeSignup,
	setAllSignupData
} = signupsSlice.actions;

export const selectField = (state, signup, field) => {
	if (signup < state.signups.signups.length)
	{
		return state.signups.signups[signup].formInfo[field];
	}
	return undefined;
}

export const selectBillerInfo = (state, signup, id, field) => {
	if (signup < state.signups.signups.length)
	{
		if (state.signups.signups[signup].formInfo.billerInfo.hasOwnProperty(id))
		{
			return state.signups.signups[signup].formInfo.billerInfo[id][field];
		}
	}
	return undefined;
}

export const selectSignupCount = (state) => {
	return state.signups.signups.length;
}

export const selectSignups = (state) => {
	return state.signups.signups;
}

export const selectSignupsRevision = (state) => {
	return state.signups.revision;
}

export const selectAllSignupData = (state) => {
	return state.signups;
}

export default signupsSlice.reducer;
