var crypto = require("crypto");

const SIGNUP_ENC_METHOD = 'aes-256-cbc';

export async function decryptSignup(key, signupDataEncrypted)
{
	let signupData = undefined;

	try
	{
		let iv = Buffer.from(signupDataEncrypted.iv, 'hex');
		let dataEncrypted = Buffer.from(signupDataEncrypted.data, 'hex');

		let cipher = crypto.createDecipheriv(SIGNUP_ENC_METHOD, key, iv);

		let decrypted = Buffer.concat([cipher.update(dataEncrypted), cipher.final()]);
		
		signupData = decrypted.toString('utf-8');
	}
	catch(e)
	{
		console.error("decryptSignup: "+e);
	}

	return signupData;
}
